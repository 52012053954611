import React from 'react';

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

export const GatewayContext = React.createContext();

export const PaymentContext = React.createContext();

export const ProfileContext = React.createContext();

