import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import InjectedAddNewPaymentForm from "./AddPaymentForm";
import { Elements } from "react-stripe-elements";
import { isEmpty } from "../../utils/Utils";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SelectPayment as useStyles, StripeElementsOptions } from "./Styles";
import { Backdrop, RenderHeader, RenderMessage, RenderSection, RenderFooter, RenderPlanTable, Typography, Select, Button } from "../UI/Core";

class SelectPayment extends Component {
  static displayName = SelectPayment.name;
  constructor(props) {
    super(props);
    this.state = {
      parentPath: "/subscription",
      cardHolderName: "",
      selectedPaymentId: props.selectedPaymentId,
      addNewPayment:this.props.addNewPayment || false,
      selectedPlan: {},
      payments: [],
      message: {},
      setDefaultPayment: (props.payments.length === 0) ? true : false,
      disableSubmit: false,
      selfUpdate: false
    }
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handlePrevStep = this.handlePrevStep.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderSelection = this.renderSelection.bind(this);
    this.handleMessageClose = this.handleMessageClose.bind(this);
    this.addNewPaymentHandler = this.addNewPaymentHandler.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let data = {}, defaultPayment;
    if (props.selectedPlan !== state.selectedPlan) {
      data = { selectedPlan: props.selectedPlan }
    }
    if (props.addNewPayment !== state.addNewPayment) {
      data = { addNewPayment: props.addNewPayment }
    }
    if (props.payments !== state.payments && !isEmpty(props.payments) && !state.selfUpdate) {
      data['setDefaultPayment'] = (props.payments.length === 0) ? true : false;
      defaultPayment = props.payments.find(payment => payment.isDefault === true);
      if (props.selectedPaymentId === "" || props.selectedPaymentId === undefined) {
        data = {
          ...data,
          payments: props.payments,
          selectedPaymentId: (defaultPayment) ? defaultPayment.id : ''
        };
      } else if (props.selectedPlan !== "" || props.selectedPlan === undefined) {
        data = {
          ...data,
          payments: props.payments,
          selectedPlan: props.selectedPlan
        }
      }
    }
    return (!isEmpty(data)) ? data : null;
  }
  componentDidMount() { }
  handleMessageClose() {
    this.setState({ message: {} })
  }
  handleNextStep(e) {
    const { selectedPaymentId, payments } = this.state;
    this.setState({ disableSubmit: true });
    //console.log(selectedPaymentId, payments);
    if (selectedPaymentId !== "" && selectedPaymentId !== undefined) {
      this.props.nextStepHandler({ step: this.props.step, selectedPaymentId: selectedPaymentId, payments: payments });
    } else if (payments.length > 0 && selectedPaymentId === "") {
      this.setState({ disableSubmit: false, message: { paymentMethodError: true } });
    } else {
      this.setState({ disableSubmit: false, message: { paymentMethodError: true } });
    }
  }

  handlePrevStep(e) {
    this.props.prevStepHandler({ step: this.props.step });
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    //localStorage.setItem("paymentGateWayPaymentId", ev.target.value);
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }

  handleCancel(e) {
    //e.preventDefault();
    //console.log(e);
    if (e && e.paymentId)
      this.setState({ addNewPayment: false, payments: e.payments, selectedPaymentId: e.paymentId, selfUpdate: true });
    else if (e && e.payments) {
      this.setState({ addNewPayment: false, payments: e.payments, selfUpdate: true });
    } else
      this.setState({ addNewPayment: false });
    this.props.history.goBack();
  }
  addNewPaymentHandler(){
    const {parentPath} = this.state;
    this.setState({ addNewPayment: true });
    this.props.history.push({pathname: `${parentPath}/add-payment`})
  }
  renderSelection() {
    const { t } = this.props;
    const { payments, selectedPlan, disableSubmit } = this.state;
    let { selectedPaymentId } = this.state;
    const { classes } = this.props;
    // page data
    //console.log(selectedPaymentId, payments,selectedPlan);
    const headerData = {
      header: t("payment:select_payment_method"),
      caption: t("payment:select_payment_method_caption")
    }

    const remarkData = [
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("payment:subscription_terms", { link: "/terms", className: classes.link }) }} className={classes.remark} />
    ]

    const footerData = [
      <Button color="secondary" onClick={this.handlePrevStep} fullWidth disabled={disableSubmit}>{t("common:back")}</Button>,
      <Button onClick={this.handleNextStep} fullWidth disabled={disableSubmit}>{t("common:next")}</Button>
    ]
    //console.log(selectedPlan);
    return (
      <Container maxWidth="md" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderPlanTable plan={selectedPlan} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            {(payments) && !isEmpty(payments) &&
              <Grid item xs={12} md={6}>
                <Select value={(selectedPaymentId !== "") ? selectedPaymentId : ""} onChange={this.handleInputChange} name="selectedPaymentId" label={t("payment:payment_method")} disabled={disableSubmit}>
                  {payments.map(payment => <option key={payment.id} value={payment.id}>{payment.brand} **** **** **** {payment.last4} {t(payment.isDefault ? "payment:default_payment" : '')}</option>)}
                </Select>
              </Grid>
            }

            <Grid item xs={12} md={6}>
              <Button color="secondary" onClick={this.addNewPaymentHandler}>{t("payment:add_new_payment_method")}</Button>
            </Grid>
          </Grid>
        </Box>

        <RenderSection data={remarkData} />
        <RenderFooter data={footerData} />
      </Container>
    )
  }

  render() {
    const { i18n, t } = this.props;
    const { addNewPayment, message, setDefaultPayment } = this.state;
    const locale = (i18n.language === "zh-CN") ? "zh" : i18n.language;
    //console.log(this.state.selectedPaymentId);
    //console.log(addNewPayment);
    const messageData = [
      {
        open: message.paymentMethodError,
        onClose: this.handleMessageClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("payment:add_new_payment_method") + ' ' + t('errors:is_required')
      }
    ]

    return (
      <Fragment>
        <RenderMessage data={messageData} />
        {addNewPayment &&
          <Elements key={this.props.i18n.language} fonts={StripeElementsOptions.fonts} locale={locale}>
            <InjectedAddNewPaymentForm setDefault={setDefaultPayment} handleCancel={this.handleCancel} />
          </Elements>
        }
        {!addNewPayment && this.renderSelection()}
      </Fragment>
    );
  }
}


export default withTranslation(["common", "payment", "errors"])(withStyles(useStyles)(SelectPayment));
