import React, { Component } from "react";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import Subscription from "./Subscription";
import Address from "./Address";
import SelectPayment from "./SelectPayment";
import PaymentResult from "./PaymentResult";
import Checkout from "./Checkout";
import Steps from "./Steps";
import { isEmpty } from "../../utils/Utils";
import { StripeProvider, Elements } from "react-stripe-elements";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { SubscriptionSteps as useStyles } from "./Styles";
import { Backdrop, RenderDialog } from "../UI/Core";
import { GatewayContext } from "../../utils/GatewayContext";
import apiRequest from "../../utils/apiRequest";

class SubscriptionSteps extends Component {
  static displayName = SubscriptionSteps.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    let params = queryString.parse(window.location.search);
    this.steps = ["subscription", 'shipping-billing', "select-payment", "checkout", "payment-result"];
    this.state = {
      parentPath: "/subscription",
      plans: [],
      userAddress: {},
      planPaging: {},
      payments: [],
      paymentPaging: {},
      addNewPayment:false,
      subscriptions: [],
      subscriptionsPaging: {},
      status: ['Trialing', 'Active'],
      //status: ['Trialing', 'Active', 'PastDue', 'Incomplete', 'IncompleteExpired', 'UnPaid', 'Canceled', 'Suspended', 'Inactive', 'Unknown'],
      stripeApiKey: process.env.REACT_APP_STRIPE_PUBLISHABLEKEY,
      noOfPlan: process.env.REACT_APP_PLAN_NO,
      selectedPaymentId: "",
      previewData: {},
      paymentResult: {},
      paymentIntent: {},
      src: params.src || "", // src is diff from stripeProductName: Campaign Asia
      planId: localStorage.getItem("paymentGateWayPlan") || "",
      currStep: (props.match.params.step) ? props.match.params.step : "subscription",
      stripe: null,
      message: {},
      token: '',
      loadingComplete: false,
      items: 100,
      selectedPayment: {},
      selectedPlan: {},
      redirect: ''

    }
    this.handleSelect = this.handleSelect.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handlePrevStep = this.handlePrevStep.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.updateUrlStep = this.updateUrlStep.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getSubscriptions = this.getSubscriptions.bind(this);
    this.getPayment = this.getPayment.bind(this);
    this.getPlans = this.getPlans.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.checkCurrStep = this.checkCurrStep.bind(this);
    this.unlisten = null;
  }

  async componentDidMount() {
    this._isMounted = true;
    const { stripeApiKey, userAddress, paymentResult, parentPath } = this.state;
    let _this = this, _defaultPayment, _userAddress, _payment, _plans, _selectedPlan, _selectedPayment, { currStep } = this.state;
    const savedPlanId = localStorage.getItem("paymentGateWayPlan") || '';
    const savedPaymentId = localStorage.getItem("paymentGateWayPaymentId") || '';

    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripeApiKey) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(stripeApiKey) });
      });
    }

    //_subscriptions = await this.getSubscriptions(true);
    _payment = await this.getPayment(true);
    _plans = await this.getPlans(true);
    _userAddress = await this.getAddress(true);

    //console.log(_subscriptions, _payment, _userAddress);
    this.setState({
      //subscriptions: _subscriptions.results, subscriptionsPaging: _subscriptions.paging,
      payments: _payment.results, paymentPaging: _payment.paging,
      plans: _plans.results, planPaging: _plans.paging,
      userAddress: _userAddress.result
    });

    _selectedPlan = this.getSelectedPlan(_plans.results, savedPlanId)

    //_selectedPlan = (!isEmpty(_plans.results[0].plans)) ? _plans.results[0].plans.find(plan => plan.id === parseInt(savedPlanId)) : undefined;
    if (!isEmpty(_payment.results)) {
      _selectedPayment = _payment.results.find(payment => payment.id === parseInt(savedPaymentId));
      _defaultPayment = _payment.results.find(payment => payment.isDefault === true);
    }


    //console.log('selected plan:',_selectedPlan,savedPlanId, _plans.results); 
    // console.log('_payment:',_payment); 
    // console.log('selectedPayment:',_selectedPayment, savedPaymentId); 
    // console.log('defaultPayment:',_defaultPayment);


    if (!savedPlanId) {
      localStorage.removeItem("paymentGateWayPaymentId");
      localStorage.removeItem("paymentGateWayPlan");
      if (currStep !== "subscription") {
        this.updateUrlStep("subscription", "REPLACE");
        //this.setState({ currStep: "subscription"});
      }
    } else if (isEmpty(userAddress) && isEmpty(_userAddress)) {
      if (currStep !== "shipping-billing") {
        this.updateUrlStep("shipping-billing", "REPLACE");
        //this.setState({ currStep: "shipping-billing"});
      }
    } else {
      //console.log(currStep);
      // landing Checking 
      switch (currStep) {
        case "add-payment":
          this.setState({addNewPayment:true, currStep:"select-payment"});
          break;
        case "select-payment":
          if (_selectedPayment) {
            // use selected payment
            this.setState({ selectedPayment: _selectedPayment, selectedPaymentId: _selectedPayment.id });
          } else if (_defaultPayment) {
            // use default payment
            this.setState({ selectedPayment: _defaultPayment, selectedPaymentId: _defaultPayment.id });
          } else {
            this.setState({ selectedPayment: {}, selectedPaymentId: '' });
          }
          break;
        case "checkout":
          if (_selectedPayment) {
            // use selected payment
            await this.previewCheckout(_selectedPayment.id).then(data => {
              _this.setState({ selectedPayment: _selectedPayment, selectedPlan: _selectedPlan, previewData: data, selectedPaymentId: _selectedPayment.id });
            });
          } else if (_defaultPayment) {
            // use default payment
            await this.previewCheckout(_defaultPayment.id).then(data => {
              _this.setState({ selectedPayment: _defaultPayment, selectedPlan: _selectedPlan, previewData: data, selectedPaymentId: _defaultPayment.id });
            });
          }
          break;
        case "payment-result":
          // clear all localstorage plan, and payment
          localStorage.removeItem("paymentGateWayPaymentId");
          localStorage.removeItem("paymentGateWayPlan");
          if (_selectedPayment && !isEmpty(paymentResult)) {
            // use selected payment
            await this.previewCheckout(_selectedPayment.id).then(data => {
              _this.setState({ selectedPayment: _selectedPayment, selectedPlan: _selectedPlan, previewData: data, selectedPaymentId: _selectedPayment.id });
            });
          } else if (!_selectedPayment && _defaultPayment && !isEmpty(paymentResult)) {
            // use default payment
            await this.previewCheckout(_defaultPayment.id).then(data => {
              _this.setState({ selectedPayment: _defaultPayment, selectedPlan: _selectedPlan, previewData: data, selectedPaymentId: _defaultPayment.id });
            });
          } else {
            this.updateUrlStep("subscription", "REPLACE");
            this.setState({ currStep: "subscription" });
          }
          break;
        case "shipping-billing": break;
        default: break;
      }
    }

    this.unlisten = this.props.history.listen((location, action) => {
      //this.checkPath(location);
      const { paymentResult } = this.state;
      //console.log(location.pathname, action);
      let _newStep = '';
      if (_this._isMounted) {
        if (location.pathname.indexOf(parentPath + '/subscription') > -1) {
          _newStep = "subscription";
        } else if (location.pathname.indexOf(parentPath + '/shipping-billing') > -1) {
          _newStep = "shipping-billing";
        } else if (location.pathname.indexOf(parentPath + '/select-payment') > -1) {
          _newStep = "select-payment";
          this.setState({addNewPayment:false});
        } else if (location.pathname.indexOf(parentPath + '/add-payment') > -1) {
          _newStep = "select-payment";
          this.setState({addNewPayment:true});
        } else if (location.pathname.indexOf(parentPath + '/checkout') > -1) {
          _newStep = "checkout";
        } else if (location.pathname.indexOf(parentPath + '/payment-result') > -1) {
          if (isEmpty(paymentResult) && action === 'POP') {
            _newStep = "subscription";
            this.updateUrlStep(_newStep);
          } else {
            localStorage.removeItem("paymentGateWayPaymentId");
            localStorage.removeItem("paymentGateWayPlan");
            _newStep = "payment-result";
            _this.setState({ currStep: _newStep });
          }
        }
        if (_newStep !== 'payment-result' && _newStep !== '') {
          _this.checkCurrStep(_newStep);
          if (!isEmpty(paymentResult))
            _this.setState({ paymentResult: {} });
        }
      }
    });

    this.setState({ loadingComplete: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.unlisten)
      this.unlisten();
  }

  async getSubscriptions(flag) {
    const { status, items } = this.state;
    try {
      const res = await apiRequest.subscription.list({ limit: items, status: status }); //All active subscriptions will be returned if status is not provided.
      if (res.status === 200)
        if (flag)
          return res.data;
        else
          this.setState({ subscriptions: res.data.results, subscriptionPaging: res.data.paging });
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden  //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async getAddress(flag) {
    try {
      const res = await apiRequest.address.list();
      if (res.status === 200) {
        if (flag)
          return res.data;
        else
          this.setState({ userAddress: res.data.results });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async getPayment(flag) {
    try {
      const { items } = this.state;
      const res = await apiRequest.paymentMethod.list({limit:items});
      if (res.status === 200) {
        if (flag)
          return res.data;
        else
          this.setState({ payments: res.data.results, paymentPaging: res.data.paging });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden
        console.log(err.response.data.error.message);
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async getPlans(flag) {
    try {
      //const res = await apiRequest.plan.list({ type: ["Private", "Public"], status:['Active','Inactive']});
      const res = await apiRequest.plan.list({ type: ["Public"], status: ['Active'] });
      if (res.status === 200) {
        if (flag)
          return res.data;
        else
          this.setState({ plans: res.data.results, planPaging: res.data.paging });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden
        console.log(err.response.data.error.message);
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async previewCheckout(paymentId) {
    try {
      const { planId } = this.state;
      const data = {
        paymentMethodId: paymentId,
        plans: [{ planId: planId, quantity: 1 }],
        //cancelAtPeriodEnd: false,
        coupon: ""
      };
      //const res = await axios.post("/api/v1/checkouts/preview", data, { headers: _token });
      const res = await apiRequest.checkout.preview(data);
      if (res.status === 200) {
        return res.data.result;
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden
        console.log(err.response.data.error.message);
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async handleNextStep(e) {
    let nextStep = "", _this = this;
    const { plans, planId } = this.state;
    let { payments } = this.state;
    //console.log(e);
    switch (e.step) {

      case "shipping-billing":
        nextStep = "select-payment";
        await this.getPayment(); // reload the latest payments
        //this.setState({ currStep: nextStep, userAddress:e.addresses });
        this.setState({ userAddress: e.addresses });
        break;
      case "select-payment":
        nextStep = "checkout";
        localStorage.setItem("paymentGateWayPaymentId", e.selectedPaymentId);
        payments = (e.payments) ? e.payments : payments;
        this.previewCheckout(e.selectedPaymentId).then(data => {
          _this.setState({
            previewData: data,
            //currStep: nextStep, 
            payments: payments,
            //selectedPlan: plans.find(plan => plan.id === parseInt(planId)),
            selectedPlan: this.getSelectedPlan(plans, planId), 
            selectedPayment: payments.find(payment => payment.id === parseInt(e.selectedPaymentId)),
            selectedPaymentId: e.selectedPaymentId
          });
        });
        break;
      case "checkout":
        nextStep = "payment-result";
        localStorage.removeItem("paymentGateWayPaymentId");
        localStorage.removeItem("paymentGateWayPlan");
        //setTimeout(()=>_this.getSubscriptions(),2000);
        this.setState({
          paymentResult: e.result,
          paymentIntent: e.paymentIntent,
          //currStep: nextStep
        });
        break;
      case "subscription":
      default:
        nextStep = "shipping-billing";
        localStorage.setItem("paymentGateWayPlan", e.planId);
        this.setState({ planId: e.planId });
        break;
    }
    this.updateUrlStep(nextStep);
  }

  getSelectedPlan(plans, id){
    
    let found = {}, result;
    if(!isEmpty(plans)){
      plans.forEach((p)=>{
        found = p.plans.find(plan => plan.id === parseInt(id))
        if(found)
          result = found;
      })
    }
    return result;
  }
  handlePrevStep(e) {
    let prevStep = "";
    switch (e.step) {
      case "payment-result":
      case "shipping-billing":
        prevStep = "subscription";
        break;
      case "select-payment":
        prevStep = "shipping-billing";
        break;
      case "checkout":
        prevStep = "select-payment";
        break;
      default: break;
    }
    this.updateUrlStep(prevStep);
  }

  handleClose() {
    this.setState({ message: {} });
  }

  updateUrlStep(step, action) {

    //let params = queryString.parse(window.location.search);
    let { history } = this.props;
    const { parentPath } = this.state;
    //console.log('updateUrlStep',step, currPath,currPath.indexOf(`${parentPath}/${step}`) );
    //if((currPath.indexOf(`${parentPath}/${step}`)===-1) ){
    if (action && step && action === "REPLACE") {
      history.replace({
        pathname: `${parentPath}/${step}`
      });
    } else if (step) {
      history.push({
        pathname: `${parentPath}/${step}`
      })
    }
    //}
  }

  handleSelect(e) {
    this.setState({
      selected_plan_id: e.stripePlanId
    })
  }
  checkCurrStep(newStep) {

    const { userAddress, currStep } = this.state;
    let correctStep = '';

    const savedPlan = localStorage.getItem("paymentGateWayPlan");
    const savedPayment = localStorage.getItem("paymentGateWayPaymentId");
    if (this._isMounted) {
      if (newStep === 'checkout' && !savedPayment && !savedPlan) {
        correctStep = 'subscription';
      } else if (newStep === 'checkout' && !savedPayment && savedPlan) {
        correctStep = 'select-payment';
      } else if ((newStep === 'select-payment')) {
        if (isEmpty(userAddress) && savedPlan)
          correctStep = 'shipping-billing';
        else if (!savedPlan)
          correctStep = 'subscription';
      } else if ((newStep === 'checkout') && isEmpty(userAddress)) {
        correctStep = 'shipping-billing';
      } else if ((newStep !== 'subscription' && !savedPlan)) {
        correctStep = 'subscription';
      }
      //console.log('checkCurrStep',savedPlan, savedPayment);
      //console.log('newStep:',newStep, 'currStep:',currStep, 'correctStep:',correctStep);
      if (correctStep !== '') {
        if (newStep !== correctStep && currStep !== newStep) {
          if (correctStep === 'subscription' && (newStep === 'select-payment' || newStep === 'select-payment'))
            this.props.history.goBack();
          else
            this.updateUrlStep(correctStep, 'REPLACE');
        }
        this.setState({ currStep: correctStep });
      } else
        this.setState({ currStep: newStep });

    }

  }
  render() {
    const { t, classes } = this.props;
    const { parentPath, redirect, message, plans, loadingComplete, /* subscriptions,  */userAddress, payments, paymentResult, stripeApiKey } = this.state;
    let { planId, currStep, selectedPaymentId, addNewPayment, paymentIntent, selectedPlan, selectedPayment, previewData } = this.state;
    // page data
    const dialogData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    //console.log(subscriptions, plans, userAddress);
    //console.log(addNewPayment);
    if (redirect !== '') {
      return (
        <Redirect to={parentPath + "/" + redirect} />
      );
    } else if (currStep && loadingComplete) {
      switch (currStep) {
        case "shipping-billing":
          return (
            <Grid container className={classes.root}>
              <Grid item className={classes.sideBar}>
                <Steps currStep={currStep} />
              </Grid>

              <Grid item className={classes.content}>
                <GatewayContext.Consumer>
                  {context =>
                    <Address
                      step={currStep}
                      userAddress={userAddress}
                      countries={context.countries}
                      nextStepHandler={this.handleNextStep}
                      prevStepHandler={this.handlePrevStep} />
                  }
                </GatewayContext.Consumer>
              </Grid>
              <RenderDialog data={dialogData} />
            </Grid>
          )
        case "select-payment":
          return (
            <StripeProvider apiKey={stripeApiKey}>
              <Elements>
                <Grid container className={classes.root}>
                  <Grid item className={classes.sideBar}>
                    <Steps currStep={currStep} />
                  </Grid>

                  <Grid item className={classes.content}>
                    <SelectPayment
                      history={this.props.history}
                      addNewPayment={addNewPayment}
                      selectedPaymentId={selectedPaymentId}
                      //selectedPlan={plans.find(plan => plan.id === parseInt(planId))}
                      selectedPlan={this.getSelectedPlan(plans, planId)}
                      step={currStep}
                      payments={payments}
                      nextStepHandler={this.handleNextStep}
                      prevStepHandler={this.handlePrevStep} />
                  </Grid>
                  <RenderDialog data={dialogData} />
                </Grid>
              </Elements>
            </StripeProvider>
          )
        case "checkout":
          return (
            <GatewayContext.Provider value={{
              plans: plans,
              selectedPayment: selectedPayment,
              selectedPlan: selectedPlan,
              userAddress: userAddress,
              previewData: previewData
            }}>
              <StripeProvider apiKey={stripeApiKey}>
                <Elements>
                  <Grid container className={classes.root}>
                    <Grid item className={classes.sideBar}>
                      <Steps currStep={currStep} />
                    </Grid>

                    <Grid item className={classes.content}>
                      <Checkout step={currStep}
                        nextStepHandler={this.handleNextStep} prevStepHandler={this.handlePrevStep} />
                    </Grid>
                    <RenderDialog data={dialogData} />
                  </Grid>
                </Elements>
              </StripeProvider>
            </GatewayContext.Provider>
          )
        case "payment-result":
          return (
            <GatewayContext.Provider value={{
              plans: plans,
              selectedPayment: selectedPayment,
              selectedPlan: selectedPlan,
              paymentResult: paymentResult,
              paymentIntent: paymentIntent
            }}>
              <StripeProvider apiKey={stripeApiKey}>
                <Elements>
                  <Grid container className={classes.root}>
                    <Grid item className={classes.sideBar}>
                      <Steps currStep={currStep} />
                    </Grid>

                    <Grid item className={classes.content}>
                      <PaymentResult step={currStep} prevStepHandler={this.handlePrevStep} />
                    </Grid>
                    <RenderDialog data={dialogData} />
                  </Grid>
                </Elements>
              </StripeProvider>
            </GatewayContext.Provider>
          )
        case "subscription":
        default:
          return (
            <GatewayContext.Provider value={{ plans: plans }}>
              <Grid container className={classes.root}>
                <Grid item className={classes.sideBar}>
                  <Steps currStep={currStep} />
                </Grid>

                <Grid item className={classes.content}>
                  <Subscription step={currStep} nextStepHandler={this.handleNextStep} />
                </Grid>
                <RenderDialog data={dialogData} />
              </Grid>
            </GatewayContext.Provider>
          )
      }
    } else {
      return <Backdrop />
    }


  }
}


export default withTranslation(["common", "payment", "errors"])(withStyles(useStyles)(SubscriptionSteps));
