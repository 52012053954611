import { createMuiTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import queryString from 'query-string'

export const defaultTheme = {
  root: { // container margin
    top: 3,
    left: 3,
    right: 3,
    bottom: 8
  },
  padding: 1.5,
  margin: 4,
  spacing: 2
}

export const ShareIconStyle = theme => ({
  success: {
    color: theme.palette.success.main
  },
  info: {
    color: theme.palette.info.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  error: {
    color: theme.palette.error.main
  }
})

// fontfamily preset
const fontFamily = {
  title: "Montserrat, Helvetica, Arial, sans-serif",
  body: "Montserrat, Helvetica, Arial, sans-serif"
}

// palette preset
const getPaletteType = mode => {
  if (mode) {
    if (mode.indexOf("dark") > -1 || mode.indexOf("black") > -1) {
      return "dark"
    } else {
      return "light"
    }
  }
}

const params = queryString.parse(window.location.search);
const paletteType = getPaletteType(params.paletteType) || getPaletteType(localStorage.getItem("paletteType")) || "light";
paletteType !== localStorage.getItem("paletteType") && localStorage.setItem("paletteType", paletteType);

const palette = (paletteType === "light") ? {
  type: "light",
  primary: {
    light: "#76CCDB",
    main: "#54C0D3",
    dark: "#3A8693",
    contrastText: "#FFF"
  },
  secondary: {
    light: "#463F7E",
    main: "#180F5E",
    dark: "#100A41",
    contrastText: "#FFF"
  },
  error: {
    light: "#F0494F",
    main: "#ED1C24",
    dark: "#A51319",
    contrastText: "#FFF"
  },
  warning: {
    light: "#F6964C",
    main: "#F47C20",
    dark: "#AA5616",
    contrastText: "#FFF"
  },
  info: {
    light: "#76CCDB",
    main: "#54C0D3",
    dark: "#3A8693",
    contrastText: "#FFF"
  },
  success: {
    light: "#33BB9D",
    main: "#00AA85",
    dark: "#00765D",
    contrastText: "#FFF"
  },
  errorBox: {
    contrastText: "#ED1C24",
    background: "rgba(255,187,0,0.12)",
    border: "rgba(255,187,0,0.23)"
  },
  text: {
    primary: "rgba(50,54,57,0.87)"
  },
  border: {
    primary: fade("#000", 0.23)
  },
  background: {
    paper: "#F7F7F7",
    default: "#FFF"
  },
  action: {
    hover: "#54C0D3"
  }
} : {
    type: "dark",
    primary: {
      light: "#76CCDB",
      main: "#54C0D3",
      dark: "#3A8693",
      contrastText: "#303030"
    },
    secondary: {
      light: "#463F7E",
      main: "#180F5E",
      dark: "#100A41",
      contrastText: "#FFF"
    },
    error: {
      light: "#F0494F",
      main: "#ED1C24",
      dark: "#A51319",
      contrastText: "#303030"
    },
    warning: {
      light: "#F6964C",
      main: "#F47C20",
      dark: "#AA5616",
      contrastText: "#303030"
    },
    info: {
      light: "#76CCDB",
      main: "#54C0D3",
      dark: "#3A8693",
      contrastText: "#303030"
    },
    success: {
      light: "#33BB9D",
      main: "#00AA85",
      dark: "#00765D",
      contrastText: "#303030"
    },
    errorBox: {
      contrastText: "#ED1C24",
      background: "rgba(255,187,0,0.12)",
      border: "rgba(255,187,0,0.23)"
    },
    text: {
      primary: "#FFF"
    },
    border: {
      primary: fade("#FFF", 0.23)
    },
    background: {
      paper: "#424242",
      default: "#303030"
    },
    action: {
      hover: "#54C0D3"
    }
  }

// mixins preset
const mixins = {
  toolbar: {
    minHeight: 56,
    "@media (min-width:0px) and (orientation: landscape)": {
      minHeight: 48
    },
    "@media (min-width:600px)": {
      minHeight: 80
    },
    "@media (min-width:600px) and (max-width:959px) and (orientation: landscape)": {
      minHeight: 56
    }
  }
}

// typography preset
const typography = {
  fontFamily: fontFamily.title,
  h1: {
    fontFamily: fontFamily.title,
    fontWeight: 400
  },
  h2: {
    fontFamily: fontFamily.title,
    fontWeight: 400
  },
  h3: {
    fontFamily: fontFamily.title,
    fontWeight: 400
  },
  h4: {
    fontFamily: fontFamily.title,
    fontWeight: 400
  },
  h5: {
    fontFamily: fontFamily.title,
    fontWeight: 400
  },
  h6: {
    fontFamily: fontFamily.title,
    fontWeight: 400
  },
  subtitle1: {
    fontFamily: fontFamily.title,
  },
  subtitle2: {
    fontFamily: fontFamily.title,
  },
  body1: {
    fontFamily: fontFamily.title,
    fontWeight: 400
  },
  body2: {
    fontFamily: fontFamily.title,
    fontWeight: 400
  },
  button: {
    fontFamily: fontFamily.title,
    fontWeight: 700,
    textTransform: "capitalize"
  }
}

// overrides preset
const overrides = {
  MuiAppBar: {
    colorPrimary: {
      color: palette.text.primary,
      backgroundColor: palette.background.paper
    }
  },
  MuiButton: {
    root: {
      minWidth: 120,
      transition: `
        color 250ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
      `
    },
    outlinedPrimary: {
      padding: "4px 14px",
      borderWidth: 2,
      "&:hover": {
        backgroundColor: fade(palette.primary.main, 0.5),
        borderWidth: 2,
      }
    },
    containedSizeLarge: {
      padding: "15px 22px"
    },
    outlinedSizeLarge: {
      padding: "13px 20px"
    },
  },
  MuiSelect: {
    select: {
      "&:focus": {
        backgroundColor: "none"
      }
    }
  },
  MuiFormControlLabel: {
    labelPlacementStart: {
      marginLeft: 0
    }
  },
  MuiOutlinedInput: {
    root: {
      "&:hover $notchedOutline": {
        borderColor: palette.primary.main
      }
    }
  },
  MuiDialogTitle: {
    root: {
      "& .MuiTypography-root": {
        fontWeight: "bold !important"
      }
    }
  }
}

// A custom theme for this app
const theme = createMuiTheme({
  mixins,
  palette,
  typography,
  overrides
});

export default theme;