import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@material-ui/core";
import { RenderPaymentMethod as useStyles } from "./Styles";
import { RenderSectionTitle } from "./Core";

RenderPaymentMethod.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
}

function RenderPaymentMethod(props) {
  const { t, paymentMethod } = props;
  const { brand, last4, isDefault, cardholderName } = paymentMethod;
  const classes = useStyles(props);

  return (
    <Box data-cy={`card-${last4}`} component="section" className={classes.root}>
      <RenderSectionTitle data={t("payment:payment_method")} />

      <Grid container className={classes.container}>
        <Grid item xs={12} md={"auto"}>
          <Typography variant="h6">{cardholderName}</Typography>
        </Grid>
        <Grid item xs={12} sm={"auto"}>
          <Typography variant="h6">{brand} **** **** **** {last4}</Typography>
        </Grid>
        <Grid item xs={12} sm={"auto"}>
          <Typography variant="h6">{isDefault && t("payment:default_payment")}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withTranslation(["common", "payment"])(RenderPaymentMethod);
