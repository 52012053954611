import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isEmpty } from "../../utils/Utils";
import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PaymentComplete as useStyles } from "../Dashboard/Styles";
import { RenderSection, RenderFooter, RenderPaymentStatusHeader, RenderPlanTable, RenderPaymentMethod, Typography, Button } from "../UI/Core";
// import { Backdrop } from "../UI/Core";
import { GatewayContext } from "../../utils/GatewayContext";

class PaymentResult extends Component {
  static displayName = PaymentResult.name;
  constructor(props) {
    super(props);
    this.state = {
      paymentResult: {},
      paymentIntent: {},
    }
  }
/*
  static getDerivedStateFromProps(props, state) {
    if (props.paymentIntent !== state.paymentIntent) {
      return {
        paymentIntent: props.paymentIntent
      }
    }
    if (props.paymentResult !== state.paymentResult) {
      return {
        paymentResult: props.paymentResult
      }
    }
    return null;
  }*/

  async componentDidMount() {
    
    const {paymentResult, paymentIntent} = this.context;
    if(isEmpty(paymentResult)){
      this.props.prevStepHandler({e:'payment-result'});
    }
    this.setState({ 
      paymentResult: paymentResult,
      paymentIntent: paymentIntent
     });
  }

  renderResult(paymentResult,paymentIntent) {
    const { t } = this.props;
    const { paymentError } = paymentResult;
    let headerData = {}, footerData = {};
    let status = '';
    //console.log(paymentResult,paymentIntent);

    if (!isEmpty(paymentResult)) { // create default status from paymentIntentStripeStatus
      status = paymentResult.paymentIntentStripeStatus;
    }
    if (paymentIntent && !isEmpty(paymentIntent)) // check succeeded
      status = paymentIntent.status;

    if ((!paymentIntent || isEmpty(paymentIntent)) && !isEmpty(paymentResult.paymentError)) // check has error code 
      status = 'errors'

    switch (status) {
      case "succeeded":
        // page data
        headerData = {
          severity: "success",
          title: t("payment:payment_successed"),
          children: t("payment:payment_successed_caption")
        };

        footerData = [
          <Button component={Link} to={"/manage/subscriptions"} fullWidth>{t("common:done")}</Button>
        ];
        break;
      case "requires_payment_method":
      case "requires_action":
        headerData = {
          severity: "error",
          title: t("stripe_errors:payment_intent_authentication_failure"),
          children: t("stripe_errors:generic_decline")
        }

        footerData = [
          <Button component={Link} to={"/manage/subscriptions" } fullWidth>{t("subscription:back_to_subscription")}</Button>
        ];
        break;
      case "canceled":
        headerData = {
          severity: "error",
          title: t("payment:subscription_canceled"),
          children: t("payment:subscription_canceled_caption")
        }

        footerData = [
          <Button component={Link} to={"/manage/subscriptions"} fullWidth>{t("subscription:back_to_subscription")}</Button>
        ];
        break;
      case "errors":
        headerData = {
          severity: "error",
          title: t('stripe_errors:' + paymentError.code),
          children: t('stripe_errors:' + paymentError.declineCode)
        }

        footerData = [
          <Button component={Link} to={"/manage/subscriptions"} fullWidth>{t("subscription:back_to_subscription")}</Button>
        ]
        break;
      default:
        break;
    }

    return {
      headerData,
      footerData
    }
  }

  render() {
    const { t, classes } = this.props;
    const { paymentResult,paymentIntent }= this.state;
    const {headerData, footerData} = this.renderResult(paymentResult,paymentIntent);
    //https://stripe.com/docs/payments/intents#intent-statuses

      return (
        <GatewayContext.Consumer>
          {({ selectedPayment}) => (
          <Fragment>
            {headerData && !isEmpty(headerData) &&
              <RenderPaymentStatusHeader {...headerData}/>
            }
            {/*<RenderPaymentStatusHeader {...this.renderResult(paymentResult,paymentIntent).headerData} />*/}
            <Container maxWidth="md" className={classes.root}>
              <RenderSection data={[
                <Typography variant="h5" className={classes.refNo}>{t("payment:invoice_number")}: {paymentResult.stripeInvoiceNumber}</Typography>
              ]} />
              <RenderPlanTable {...paymentResult} />
              <RenderPaymentMethod paymentMethod={selectedPayment} />
              <RenderSection data={[
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("payment:subscription_terms", { link: "/terms", className: classes.link }) }} className={classes.remark} />
              ]} />
              {footerData && !isEmpty(footerData) &&
                <RenderFooter data={footerData} />
              }
              {/* <RenderFooter data={this.renderResult(paymentResult,paymentIntent).footerData} /> */}
            </Container>
          </Fragment>
          )}  
        </GatewayContext.Consumer>
      )
    /*} else {
      return <Backdrop />
    }*/
  }
}

PaymentResult.contextType = GatewayContext;
export default withTranslation(["common", "payment", "errors"])(withStyles(useStyles)(PaymentResult));