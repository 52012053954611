import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button as MuiButton } from '@material-ui/core';

Button.propTypes = {
  gutters: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary", "third", "success", "info", "warning", "error"]),
  className: PropTypes.string
}

const useStyles = makeStyles(theme => ({
  root: {},
  gutters: {
    margin: theme.spacing(2, 0, 1)
  },
  color: props => (
    props.color === "success" ||
    props.color === "info" ||
    props.color === "warning" ||
    props.color === "error"
  ) && ({
    backgroundColor: theme.palette[props.color].main,
    "&:hover": {
      backgroundColor: theme.palette[props.color].dark
    }
  }),
}));

export default function Button(props) {
  const { gutters, color, className, ...rest } = props;
  const classes = useStyles(props);

  return (
    <MuiButton
      color={color === "third" ? "secondary" : "primary"}
      variant={color === "secondary" ? "outlined" : "contained"}
      size="large"
      // disableElevation
      className={clsx(
        classes.root,
        gutters && classes.gutters,
        (
          props.color === "success" ||
          props.color === "info" ||
          props.color === "warning" ||
          props.color === "error"
        ) && classes.color,
        className
      )}
      {...rest}
    >{props.children}</MuiButton>
  )
}
