import React from "react";
// import { Link } from "react-router-dom";
// import { withTranslation } from "react-i18next";
import { Container, Box } from "@material-ui/core";
import { RenderFooter, Typography } from "./Core";
import { Message as useStyles } from "./Styles";

export default function Message(props) {
  const { message, buttons } = props;
  const classes = useStyles(props);
  const theme = "signup";

  // page data
  const footerData = buttons;

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h5">{message || "Loading..."}</Typography>

        <RenderFooter data={footerData} theme={theme} />
      </Box>
    </Container>
  )
}

// export default withTranslation(["common"])(Message);
