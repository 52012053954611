import React from "react";
import PropTypes from 'prop-types';
import { getLanguageCode } from "../../utils/Utils";
import clsx from "clsx";
import { CircularProgress, Box, Typography, Backdrop as MuiBackdrop } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { fade } from "@material-ui/core/styles/colorManipulator";
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 3,
  },
  processing: {
    backgroundColor: fade(theme.palette.common.black, 0.8)
  },
  processingText: {
    color: theme.palette.common.white
  }
}))

export default function Backdrop(props) {
  const classes = useStyles();
  const { variant, message, open } = props;
  const processing = (variant === "processing");
  const curr_lang = getLanguageCode(queryString.parse(window.location.search).lang) || getLanguageCode(localStorage.getItem("lang")) || getLanguageCode((navigator.language || navigator.userLanguage).toLowerCase());
  let defaultMessage = {};
  if (curr_lang === "zh-CN") {
    defaultMessage = {
      loading: "载入中...",
      processing: "处理中..."
    }
  } else if (curr_lang === "ja") {
    defaultMessage = {
      loading: "読み込み中...",
      processing: "処理..."
    }
  } else {
    defaultMessage = {
      loading: "Loading...",
      processing: "Processing..."
    }
  }

  return (
    <MuiBackdrop
      className={clsx(
        classes.backdrop,
        processing && classes.processing
      )}
      open={ open === undefined ? true : open }
      invisible={!processing ? true : false }
    >
      <Box textAlign="center">
        <CircularProgress />
        <Typography
          variant="body2"
          className={processing ? classes.processingText : ""}
        >{message ? message : !processing ? defaultMessage.loading : defaultMessage.processing}</Typography>
      </Box>
    </MuiBackdrop>
  )
}

Backdrop.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.oneOf(["loading", "processing"]),
  open: PropTypes.bool
}