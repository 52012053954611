import React, { Component, Fragment } from 'react';
import { withTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { RenderSection, RenderPlanTable, RenderPaymentMethod, RenderFooter, RenderPaymentStatusHeader, Typography, Button } from "../UI/Core";
import { PaymentComplete as useStyles } from "./Styles";
import { isEmpty, tokenHeader } from "../../utils/Utils";
import { Backdrop } from "../UI/Core";

class PaymentComplete extends Component {
  static displayName = PaymentComplete.name;

  constructor(props) {
    super(props);
    this.state = {
      token: {},
      payment: {},
      invoice: {},
      // paymentResult: {
      //   "stripeInvoiceId": "in_1HRatdEwLysLwiwKQrdKaKfV",
      //   "subscriptionStripeStatus": "active",
      //   "invoiceStripeStatus": "paid",
      //   "paymentIntentStripeStatus": "succeeded",
      //   "stripeSubscriptionId": "sub_I1eCvIbJ72hShC",
      //   "stripeInvoiceNumber": "577D2D70-0014"
      // }
    };

    /* C#
      namespace Stripe
      {
          public static class SubscriptionStatuses
          {
            // no need
              public const string Trialing = "trialing";
            // no need
              public const string Active = "active";
              public const string PastDue = "past_due";
            // no need
              public const string Canceled = "canceled";
            // won't happen when pay by credit card
              public const string Unpaid = "unpaid";
              public const string Incomplete = "incomplete";
            // no need
              public const string IncompleteExpired = "incomplete_expired";
          }
      }
    */
    this.renderResult = this.renderResult.bind(this);
  }

  async componentDidMount() {
    let invoice;
    const { selectedPaymentId, paymentMethod, invoices, latestPayment, payments } = this.props;
    const token = await tokenHeader();
    const payment = (selectedPaymentId) ? payments.find(o => o.id === parseInt(selectedPaymentId))
      : payments.find(o => o.id === paymentMethod.id);
    if(latestPayment)
      invoice = invoices.find(o => o.stripeInvoiceId === latestPayment.stripeInvoiceId);
    else if(invoices.length>0)
      invoice = invoices[0];
    
    this.setState({ token: token, payment: payment, invoice: invoice });
  }

  renderResult(status) {
    const { t, paymentResult } = this.props;
    let headerData = {}, footerData = {};

    switch (status) {
      case "succeeded":
        // page data
        headerData = {
          severity: "success",
          title: t("payment:payment_successed"),
          children: t("payment:payment_successed_caption")
        };

        footerData = [
          <Button color="secondary" onClick={() => this.props.onCancel('')} fullWidth>{t("common:done")}</Button>
        ];
        break;
      case "requires_payment_method":
      case "requires_action":
        headerData = {
          severity: "error",
          title: t("stripe_errors:payment_intent_authentication_failure"),
          children: t("stripe_errors:generic_decline")
        }

        footerData = [
          <Button color="secondary" onClick={() => this.props.onCancel('')} fullWidth>{t("common:cancel")}</Button>,
          <Button onClick={() => this.props.onCancel('showInvoice')} fullWidth>{t("payment:retry")}</Button>
        ];
        break;
      case "canceled":
        headerData = {
          severity: "error",
          title: t("payment:subscription_canceled"),
          children: t("payment:subscription_canceled_caption")
        }

        footerData = [
          <Button color="secondary" onClick={() => this.props.onCancel('')} fullWidth>{t("common:cancel")}</Button>,
          <Button onClick={() => this.props.onCancel('showInvoice')} fullWidth>{t("payment:retry")}</Button>
        ];
        break;
      case "errors":
        headerData = {
          severity: "error",
          title: t('stripe_errors:' + paymentResult.paymentError.code),
          children: t('stripe_errors:' + paymentResult.paymentError.declineCode)
        }

        footerData = [
          <Button color="secondary" onClick={() => this.props.onCancel('')} fullWidth>{t("common:cancel")}</Button>,
          <Button onClick={() => this.props.onCancel('showInvoice')} fullWidth>{t("payment:retry")}</Button>
        ]
        break;
      default:
        break;
    }

    return {
      headerData,
      footerData
    }
  }

  render() {
    const { paymentResult, paymentIntent, t, classes } = this.props;
    const { payment, invoice } = this.state;
    const { stripeInvoiceNumber } = invoice;
    let data, status;

    //https://stripe.com/docs/payments/intents#intent-statuses
    if (!isEmpty(paymentResult)) { // create default status from paymentIntentStripeStatus
      status = paymentResult.paymentIntentStripeStatus;
    }
    if (paymentIntent && !isEmpty(paymentIntent)) // check succeeded
      status = paymentIntent.status;

    if ((!paymentIntent || isEmpty(paymentIntent)) && !isEmpty(paymentResult.paymentError)) // check has error code 
      status = 'errors'

    data = this.renderResult(status); // render

    if (!isEmpty(paymentResult) && !isEmpty(data.headerData) && !isEmpty(data.footerData)) {
      return (
        <Fragment>
          <RenderPaymentStatusHeader {...data.headerData} />
          <Container maxWidth="md" className={classes.root}>
            <RenderSection data={[
              <Typography variant="h5" className={classes.refNo}>{t("payment:invoice_number")}: {stripeInvoiceNumber}</Typography>
            ]} />
            <RenderPlanTable invoice={invoice} {...paymentResult} />
            <RenderPaymentMethod paymentMethod={payment} />
            <RenderSection data={[
              <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("payment:subscription_terms", { link: "/terms", className: classes.link }) }} className={classes.remark} />
            ]} />
            <RenderFooter data={data.footerData} />
          </Container>
        </Fragment>
      )
    } else {
      return <Backdrop />;
    }
  }
}

export default withTranslation(["common", "payment", "subscription"])(withStyles(useStyles)(PaymentComplete));