import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { isEmpty } from "../../utils/Utils";
import { withStyles } from "@material-ui/core/styles";
import { Container, Box, Grid } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { Subscription as useStyles } from "./Styles";
import { Backdrop, RenderHeader, RenderSection, Typography, ErrorMessage, Button } from "../UI/Core";
import { GatewayContext } from "../../utils/GatewayContext";
import { CommaFormattedPrice } from "../../utils/Utils";

class Subscription extends Component {
    static displayName = Subscription.name;
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            disableSubmit: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleNextStep = this.handleNextStep.bind(this);
    }

    async componentDidMount() {

    }

    handleInputChange(e) {
        const name = e.target.name;
        this.setState({
            ...this.state,
            [name]: e.target.value
        });
    }

    handleSelect(planId) {
        this.setState({ planId: planId }, this.handleNextStep)
    }

    handleNextStep() {
        let errors = {};
        const { planId } = this.state;
        this.setState({ disableSubmit: true });

        if (planId === "") {
            errors["subscription"] = true;
            this.setState({ disableSubmit: false });
        } else {
            this.props.nextStepHandler({ step: this.props.step, planId: this.state.planId });
        }
        this.setState({ errors: errors });
    }

    render() {
        const { t, classes } = this.props;
        const { errors, disableSubmit, planId } = this.state;

        const headerData = {
            header: t("payment:select_subscription"),
            caption: t("payment:select_subscription_caption")
        }

        const remarkData = [
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("payment:subscription_terms", { link: "/terms", className: classes.link }) }} className={classes.remark} />
        ]

        return (

            <GatewayContext.Consumer>
                {({ plans }) => (
                    <Container maxWidth="md" className={classes.root}>
                        <Backdrop variant="processing" open={disableSubmit} />
                        <RenderHeader {...headerData} />

                        {!isEmpty(plans) ?
                            <>
                                {plans.map((items, i) =>
                                    <Box key={i} component="section" className={classes.sectionRoot}>
                                        <Grid container className={classes.gridContainer} alignItems="flex-end">
                                            <Grid item xs={7} sm={8} md={9}>
                                                {items.name == "Our brands" && <Typography variant="body1" className={classes.title}>{t("payment:select_subscription")}</Typography>}
                                                {items.name == "Other brands" && <Typography variant="body1" className={classes.title}>{items.name}</Typography>}
                                                {items.name != "Our brands" && items.name != "Other brands" && <Typography variant="h5" className={classes.title}>{items.name}</Typography>}
                                            </Grid>
                                            <Grid item xs={5} sm={4} md={3}>
                                                <Typography variant="body1" className={clsx(classes.title, classes.end)}>{t("payment:price")}</Typography>
                                            </Grid>
                                        </Grid>

                                        {items.plans.map((item, key) => <RenderSubscriptionButton {...this.props} isDisabled={!item.canClicked} item={item} key={key} planId={planId} onClick={this.handleSelect} />)}
                                    </Box>
                                )}
                                {(errors["subscription"]) && <ErrorMessage>{t("common:subscription") + t("errors:is_required")}</ErrorMessage>}
                            </>
                            : <Alert severity="info" icon={false}>{t("subscription:no_valid_subscription_select")}</Alert>
                        }
                        <RenderSection data={remarkData} />
                    </Container>
                )}
            </GatewayContext.Consumer>
        )
    }
}

class RenderSubscriptionButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planId: this.props.planId,
            checkDuplicate: false
        }
    }


    render() {
        //console.log(this.props.item);
        const { t, classes, item, onClick, isDisabled } = this.props;
        const selected = (this.state.planId === this.props.item.id);
        //const found = subscriptions.find((o) => o.stripePlanId === this.props.item.stripePlanId);
        //const disable = (found && this.state.checkDuplicate) ? true : this.props.disableSubmit;

        return (
            <Button color="secondary" fullWidth onClick={() => onClick(this.props.item.id)} className={clsx(classes.button, (selected) && classes.highlight)} disabled={isDisabled} >
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={7} sm={8} md={9} className={classes.item}>
                        <Typography variant="h6" className={classes.body}>{item.stripeProductName} {(item.stripeNickname !== undefined && item.stripeNickname !== null && item.stripeNickname !== "") ? ` - ${item.stripeNickname}` : ""} {(item.trialDays !== 0) ? ` - ${t("subscription:trial_days")}: ${item.trialDays}` : ""}</Typography>
                    </Grid>
                    <Grid item xs={5} sm={4} md={3} className={classes.item}>
                        <Typography variant="h6" className={clsx(classes.body, classes.end, classes.currency)}>{item.currency} {CommaFormattedPrice((item.amount / 100).toFixed(2))}</Typography>
                        <Typography variant="h6" className={clsx(classes.body, classes.end, classes.currency)}>{item.priceDescription}</Typography>
                    </Grid>
                </Grid>
            </Button>
        )
    }
}

export default withTranslation(["common", "payment", "subscription", "errors"])(withStyles(useStyles)(Subscription));
