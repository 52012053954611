import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { tokenHeader} from "../../utils/Utils";

import ReCAPTCHA from "react-google-recaptcha";
import { Container, Box, Grid, InputAdornment, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Backdrop, RenderHeader, RenderFooter, RenderMessage, Typography, TextField, ErrorMessage, Button } from "../UI/Core";
import { Password as useStyles } from "./Styles";
import apiRequest from "../../utils/apiRequest";

class Password extends Component {
  static displayName = Password.name;

  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();

    this.state = {
      errors: {},
      password: "",
      newPassword: "",
      confirmNewPassword: "",
      disableSubmit: false,
      token: '',
      message: {},
      success: false,
      showPassword: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleValidation = this.handleValidation.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderCompleted = this.renderCompleted.bind(this);
    this.renderChangePassword = this.renderChangePassword.bind(this);
  }

  async componentDidMount() {
    this.setState({ token: await tokenHeader() });
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    const { newPassword, confirmNewPassword, password } = this.state;
    
    // if (!this.handleValidation()) {
      try {
        this.recaptchaRef.current.reset();
        const token = await this.recaptchaRef.current.executeAsync();
        //console.log(token);
        this.setState({
          disableSubmit: true,
          errors: {},
          message: {}
        });
        const data = {
          "email": "",
          "token": "",
          "password": password,
          "newPassword": newPassword,
          "confirmNewPassword": confirmNewPassword,
          "recaptchaResponse": token
        };

        const resend_resp = await apiRequest.account.password(false, data);
        //const resend_resp = await axios.patch("./api/v1/account/password", data, { headers: this.state.token });
        if (resend_resp.status === 200) {
          //window.location.href = '/reset-password-email/' + this.state.email;
          this.setState({
            success: true,
            message: { passwordChanged: true },
            password: '',
            newPassword: '',
            confirmNewPassword: '',
            disableSubmit: false,
            recaptchaResponse: ''
          })
        }
      } catch (err) {
        console.log(err);
        this.setState({ disableSubmit: false });
        if (err.response.status === 400) {
          // this.setState({
          //   message: { badRequestError: true }
          // });
          const { error } = err.response.data;
          let errors = {};
          if (error.params) {
            Object.keys(error.params).forEach(function (key) {
              if (key === "newPassword") {
                errors[key] = [];
                error.params[key].forEach(function (item, i) {
                  errors[key][i] = "errors:" + item;
                })
              } else {
                errors[key] = "errors:" + error.params[key][0];
              }
            });
            this.setState({ errors: errors })
          }
        } else if (err.response.status === 500) {
          this.setState({
            message: { internalServerError: true }
          });
        }
      }
    // }
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }

  // handleValidation() {
  //   const { newPassword, confirmNewPassword, password } = this.state;
  //   let errors = {};
  //   if (newPassword === "") {
  //     errors["newPassword"] = "errors:is_required";
  //   } else if (!passwordCheck(newPassword)) {
  //     errors["newPassword"] = "errors:password_error";
  //   }
  //   if (newPassword !== confirmNewPassword) {
  //     errors["confirmNewPassword"] = "errors:not_matched";
  //   }
  //   if (password === "") {
  //     errors["password"] = "errors:is_required";
  //   } else if (!passwordCheck(password)) {
  //     errors["password"] = "errors:invalid";
  //   }
  //   if (Object.keys(errors).length) {
  //     this.setState({ errors: errors });
  //     return true;
  //   } else {
  //     this.setState({ errors: {} });
  //     return false;
  //   }
  // }

  handleClose() {
    this.setState({ message: {} })
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      ...this.state,
      showPassword: !showPassword
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  renderCompleted() {
    const { t, classes } = this.props;
    const { message } = this.state;

    // page data
    const headerData = {
      header: t("password:password_changed")
    }

    const messageData = [
      {
        open: message.passwordChanged,
        onClose: this.handleClose,
        severity: "success",
        children: t("password:password_changed")
      }
    ]

    const footerData = [
      <Button component={Link} to="/" fullWidth>{t("dashboard:dashboard")}</Button>
    ]

    return (
      <Container maxWidth="sm" className={classes.root}>
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs>
              <Typography dangerouslySetInnerHTML={{ __html: t("password:password_changed_content") }} variant="body1" component="div" />
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} />
      </Container>
    )
  }

  renderChangePassword() {
    const { t, classes } = this.props;
    const { errors, message, disableSubmit, password, newPassword, confirmNewPassword, showPassword } = this.state;

    const headerData = {
      header: t("password:change_password"),
      caption: t("password:change_password_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const footerData = [
      <Button onClick={this.handleSubmit} fullWidth disabled={disableSubmit}>{t("common:submit")}</Button>
    ]

    return (
      <Container maxWidth="sm" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <TextField value={password} onChange={this.handleInputChange} id="password" name="password" type={showPassword ? "text" : "password"} InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}> {showPassword ? <Visibility /> : <VisibilityOff />} </IconButton>
                  </InputAdornment>
              }} label={t("password:current_password") + " *"} error={Boolean(errors["password"])} disabled={disableSubmit} />
              {(errors["password"]) && <ErrorMessage>{t("password:current_password")}{t(errors["password"])}</ErrorMessage>}
            </Grid>

            <Grid item xs={12}>
              <TextField value={newPassword} onChange={this.handleInputChange} id="newPassword" name="newPassword" type={showPassword ? "text" : "password"} InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}> {showPassword ? <Visibility /> : <VisibilityOff />} </IconButton>
                  </InputAdornment>
              }} label={t("password:new_password") + " *"} error={Boolean(errors["newPassword"])} disabled={disableSubmit} />
              {(errors["newPassword"]) && errors["newPassword"].map((item, idx) => <ErrorMessage key={idx}>{t("password:new_password")}{t(item)}</ErrorMessage>)}
            </Grid>

            <Grid item xs={12}>
              <TextField value={confirmNewPassword} onChange={this.handleInputChange} id="confirmNewPassword" name="confirmNewPassword" type={showPassword ? "text" : "password"} InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}> {showPassword ? <Visibility /> : <VisibilityOff />} </IconButton>
                  </InputAdornment>
              }} label={t("password:confirm_password") + " *"} error={Boolean(errors["confirmNewPassword"])} disabled={disableSubmit} />
              {(errors["confirmNewPassword"]) && <ErrorMessage>{t("password:confirm_password")}{t(errors["confirmNewPassword"])}</ErrorMessage>}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" align="left" className={classes.remarks}>{t("password:password_requirement")}</Typography>
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} />
        
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          ref={this.recaptchaRef}
          size="invisible"
        />
      </Container>
    )
  }

  render() {
    const { success } = this.state;

    if (success) {
      return this.renderCompleted();
    } else {
      return this.renderChangePassword();
    }
  }
}

export default withTranslation(["common", "errors", "dashboard", "password"])(withStyles(useStyles)(Password));