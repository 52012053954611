import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { isEmpty, renewTokenHeader } from "../../utils/Utils";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add as AddIcon, ArrowDownward as ArrowDownwardIcon, TransferWithinAStationSharp } from '@material-ui/icons';
import { Subscriptions as useStyles } from "./Styles";
import { RenderHeader, RenderMessage, RenderFeatureButton, RenderSectionTitle, RenderSubscription, IconLabelButton, RenderTenant } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";
import LegacySubscriptions from './LegacySubscriptions';


class Subscriptions extends Component {
  static displayName = Subscriptions.name;

  constructor(props) {
    super(props);
    this.state = {
      message: {},
      token: {},
      plans: [],
      planPaging: {},
      types: ['warning', 'valid', 'invalid'],
      warning: {
        status: ['PastDue', 'Incomplete'],
        subscriptions: [],
        subsPaging: {},
      },
      valid: {
        status: ['Trialing', 'Active', 'NotStarted' ],
        subscriptions: [],
        subsPaging: {},
      },
      invalid: {
        status: ['UnPaid', 'Canceled', 'Suspended'],
        subscriptions: [],
        subsPaging: {},
      },
      corporates: {
        subscriptions: [],
        subsPaging: {}
      },
      items: 20,
      corporateItems: 100,
      plansItems: 50,
      focus: {}
    }
    // subscription status
    // trialing, active, incomplete, incomplete_expired, past_due, canceled, unpaid
    //this.getPlans = this.getPlans.bind(this);
    this.getMore = this.getMore.bind(this);
    this.getSubscriptions = this.getSubscriptions.bind(this);
    this.handleShowExpired = this.handleShowExpired.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  async componentDidMount() {

    const { items, corporateItems, warning, valid, invalid } = this.state;
    try {
      const valids = await apiRequest.subscription.list({ limit: items, status: valid.status });
      const invalids = await apiRequest.subscription.list({ limit: items, status: invalid.status });
      const warnings = await apiRequest.subscription.list({ limit: items, status: warning.status });

      const validCorporates = await apiRequest.corporate.list({ limit: corporateItems, status: valid.status })
      const invalidCorporates = await apiRequest.corporate.list({ limit: corporateItems, status: invalid.status })
      const warningCorporates = await apiRequest.corporate.list({ limit: corporateItems, status: warning.status })
      const tenants = await apiRequest.tenants.myTenants({ limit: 1000 });

      tenants.data.results.forEach(r => {
        r.isTenant = true
      });

      this.setState({
        warning:{
          ...this.state.warning,
          subscriptions: [ ...warnings.data.results, ...tenants.data.results.filter(r => warning.status.includes(r.status)) ],
          subsPaging: warnings.data.paging,
        },
        valid:{
          ...this.state.valid,
          subscriptions: [ ...valids.data.results, ...tenants.data.results.filter(r => valid.status.includes(r.status)) ],
          subsPaging: valids.data.paging,
        },
        invalid:{
          ...this.state.invalid,
          subscriptions: [ ...invalids.data.results, ...tenants.data.results.filter(r => invalid.status.includes(r.status)) ],
          subsPaging: invalids.data.paging,
        },
        corporates: {
          ...this.state.corporates,
          subscriptions: [ ...validCorporates.data.results, ...invalidCorporates.data.results, ...warningCorporates.data.results ],
          subsPaging: validCorporates.data.paging
        }
      })
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }

    }
  }

  async getPlans(_token) {
    try {//, {params: { limit: this.state.items }
      //axios.get(URL, { params:{}, headers: { 'Authorization': AuthStr } })
      const res = await axios.get('/api/v1/plans', { headers: _token, params: { limit: this.state.plansItems } });
      if (res.status === 200)
        this.setState({ plans: res.data.results, planPaging: res.data.paging });
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        const token = await renewTokenHeader();
        this.getPlans(token);
        this.setState({ token: token })
      } else if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async getSubscriptions(_token, type) {
    const { items, types } = this.state;
    const { status } = this.state[types.indexOf(type) !== -1 ? type : "valid"];
    try {
      const res = await axios.get('/api/v1/subscriptions', { headers: _token, params: { limit: items, status: status }, paramsSerializer: params => queryString.stringify(params) });
      if (res.status === 200) {
        //console.log(res.data.results);
        this.setState({
          [type]: {
            status: status,
            subscriptions: res.data.results,
            subsPaging: res.data.paging
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        _token = await renewTokenHeader();
        this.getSubscriptions(_token);
        this.setState({ token: _token })
      } else if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async getMore(type) {
    const { items } = this.state;
    //const { status, subscriptions, subsPaging } = this.state[types.indexOf(type) !== -1 ? type : "valid"];
    const { status, subscriptions, subsPaging } = this.state[type];
    const { cursor} = subsPaging;
    try {
      const res = await apiRequest.subscription.list({ limit: items, status: status, cursor: cursor});
      if (res.status === 200) {
        let newItems = subscriptions.concat(res.data.results);
        this.setState({
          [type]: {
            status: status,
            subscriptions: newItems,
            subsPaging: res.data.paging
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  handleShowExpired() {
    this.setState({
      showExpired: true
    })
  }

  handleClose() {
    this.setState({
      message: {}
    });
  }

  render() {
    const { t, classes } = this.props;
    const { message, warning, valid, invalid, corporates } = this.state;

    //console.log(gotExpiredItems,subscriptions);

    // page data
    const headerData = {
      header: t("subscription:subscriptions"),
      caption: t("subscription:subscriptions_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]
    const featureData = [
      <IconLabelButton color="warning" startIcon={<AddIcon />} component={Link} to="/subscription/subscription">{t("subscription:join_subscription")}</IconLabelButton>,
      // <IconLabelButton component={Link} to="/manage/subscriptions/legacy">{t("subscription:legacy_subscriptions")}</IconLabelButton>,
      !isEmpty(corporates.subscriptions) && <IconLabelButton color="secondary" component={Link} to="/manage/corporate">{t("subscription:corporate_subscription")}</IconLabelButton>
    ];

    return (
      <React.Fragment>
        <Container maxWidth="md" className={classes.root}>
          <RenderHeader {...headerData} />
          <RenderMessage data={messageData} />
          <RenderFeatureButton data={featureData} />

          {!isEmpty(warning.subscriptions) &&
            <Box data-cy="subscription-reminder" component="section" className={classes.sectionRoot}>
              <RenderSectionTitle data={t("subscription:subscription_reminder")} />
              <Grid container className={classes.gridContainer}>
                {warning.subscriptions.map((item, key) => item.isTenant ? <RenderTenant key={key} item={item} /> : <RenderSubscription key={key} item={item}  />)}
                {warning.subsPaging.hasMore &&
                  <Grid item xs={12} className={classes.item}>
                    <Box textAlign="center">
                      <IconLabelButton key={warning.subscriptions.length} startIcon={<ArrowDownwardIcon />} onClick={() => this.getMore("warning")}>{t("common:show_more")}</IconLabelButton>
                    </Box>
                  </Grid>
                }
              </Grid>
            </Box>
          }

          <Box data-cy="subscription-valid" component="section" className={classes.sectionRoot}>
            <RenderSectionTitle data={t("subscription:valid_subscriptions")} />
            <Grid container className={classes.gridContainer}>
              {(!isEmpty(valid.subscriptions)) ?
                <Fragment>
                  {valid.subscriptions.map((item, key) => item.isTenant ? <RenderTenant key={key} item={item} /> : <RenderSubscription key={key} item={item} />)}
                  {valid.subsPaging.hasMore &&
                    <Grid item xs={12} className={classes.item}>
                      <Box textAlign="center">
                        <IconLabelButton key={valid.subscriptions.length} startIcon={<ArrowDownwardIcon />} onClick={() => this.getMore("valid")}>{t("common:show_more")}</IconLabelButton>
                      </Box>
                    </Grid>
                    // : <Grid item xs={12} className={classes.item}>{t("common:end")}</Grid>
                  }
                </Fragment>
                : <Grid item xs={12} className={classes.item}>{t("subscription:no_subscription")}</Grid>
              }
            </Grid>
          </Box>

          <Box data-cy="subscription-invalid" component="section" className={classes.sectionRoot}>
            <RenderSectionTitle data={t("subscription:invalid_subscriptions")} />
            <Grid container className={classes.gridContainer}>
              {(!isEmpty(invalid.subscriptions)) ?
                <Fragment>
                  {invalid.subscriptions.map((item, key) => item.isTenant ? <RenderTenant key={key} item={item} /> : <RenderSubscription key={key} item={item} />)}
                  {invalid.subsPaging.hasMore &&
                    <Grid item xs={12} className={classes.item}>
                      <Box textAlign="center">
                        <IconLabelButton key={invalid.subscriptions.length} startIcon={<ArrowDownwardIcon />} onClick={() => this.getMore("invalid")}>{t("common:show_more")}</IconLabelButton>
                      </Box>
                    </Grid>
                    // : <Grid item xs={12} className={classes.item}>{t("common:end")}</Grid>
                  }
                </Fragment>
                : <Grid item xs={12} className={classes.item}>{t("subscription:no_subscription")}</Grid>
              }
            </Grid>
          </Box>

        </Container>
        <LegacySubscriptions showFooter={false} />
      </React.Fragment>
    )
  }
}

export default withTranslation(["common", "subscription", "errors"])(withStyles(useStyles)(Subscriptions));
