import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { defaultTheme } from "../../themes/theme";
import ColumnGrid from "../../themes/ColumnGrid";

const ShareStyle = theme => {
  const { margin, spacing } = defaultTheme;

  return {
    root: {},
    sectionRoot: {
      margin: theme.spacing(margin, 0)
    },
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    }
  }
}

export const Footer = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    fontSize: 12
  },
  link: {
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.action.hover
    }
  }
}))

export const AuthorizeLayout = makeStyles(theme => ({
  root: {
    overflow: "hidden"
  },
  container: {
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    flexFlow: "column nowrap",

    [theme.breakpoints.up(0) + " and (orientation: landscape)"]: {
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"].minHeight}px)`,
    },

    [theme.breakpoints.up("sm")]: {
      flexFlow: "row nowrap",
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    },

    [theme.breakpoints.only("sm") + " and (orientation: landscape)"]: {
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px) and (max-width:959px) and (orientation: landscape)"].minHeight}px)`,
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: "100%",
    flexBasis: 0,
    backgroundColor: (theme.palette.type === "light") ? theme.palette.grey["200"] : "inherit"
  }
}))

export const AuthorizeFooter = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "none",
      paddingBottom: theme.spacing(4),
      fontSize: 12,
      backgroundColor: (theme.palette.type === "light") ? theme.palette.grey["200"] : "inherit",
      overflow: "hidden"
    },
    [theme.breakpoints.up("sm")]: {
      flexFlow: "column",
      position: "absolute",
      bottom: 0,
      width: "30%",
      maxWidth: 256,
      paddingBottom: theme.spacing(4),
      fontSize: 12,
      color: "white",
      overflow: "hidden",
      zIndex: theme.zIndex.drawer - 1
    }
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      ...ColumnGrid({ theme: theme, argument: 1 }),
      justifyContent: "center"
    },
    [theme.breakpoints.up("sm")]: {
      ...ColumnGrid({ theme: theme, argument: 0 })
    }
  },
  link: {
    color: "currentColor",

    '&:hover': {
      color: theme.palette.action.hover
    }
  }
}))

export const Header = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer - 1
  },
  logo: {
    display: "block",
    height: 32,
    width: "auto"
  },
  spacing: {
    flexGrow: 1,
    padding: theme.spacing(0, 1)
  },
  email: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: theme.spacing(2),
    overflow: "hidden",
  }
}))

export const LanguageClass = theme => ({
  select: {
    height: theme.spacing(3),
    padding: theme.spacing(0.5) + "px " + theme.spacing(4) + "px " + theme.spacing(0.5) + "px 14px",
    fontSize: "0.75rem",
    lineHeight: "200%"
  }
})

export const Language = makeStyles(theme => ({
  select: {
    height: theme.spacing(3),
    padding: theme.spacing(0.5) + "px " + theme.spacing(4) + "px " + theme.spacing(0.5) + "px 14px",
    fontSize: "0.75rem",
    lineHeight: "200%"
  }
}))

export const Menu = makeStyles(theme => ({
  button: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  sideBar: {
    width: "30%",
    maxWidth: 256,
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    zIndex: theme.zIndex.drawer - 2,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  paper: {
    width: "80%",
    maxWidth: 256,
    color: "white",
    backgroundColor: theme.palette.secondary.main
  },
  list: {},
  item: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight: 48,
    whiteSpace: "normal"
  },
  highlight: {
    backgroundColor: fade(theme.palette.primary.main, 0.25)
  }
}))

export const Terms = makeStyles(theme => ({
  ...ShareStyle(theme),
  textArea: {
    fontSize: theme.typography.htmlFontSize,
    // margin: theme.spacing(2, 0, 1),
    "& a": {
      color: theme.palette.text.primary,
  
      '&:hover': {
        color: theme.palette.action.hover
      }
    },
    "& p": {
      lineHeight: 1.5
    },
    "& > :first-child": {
      marginTop: 0
    },
    "& > :last-child": {
      marginBottom: 0
    }
  }
}))

export const Privacy = makeStyles(theme => ({
  ...ShareStyle(theme),
  textArea: {
    fontSize: theme.typography.htmlFontSize,
    // margin: theme.spacing(2, 0, 1),
    "& a": {
      color: theme.palette.text.primary,
  
      '&:hover': {
        color: theme.palette.action.hover
      }
    },
    "& p": {
      lineHeight: 1.5
    },
    "& > :first-child": {
      marginTop: 0
    },
    "& > :last-child": {
      marginBottom: 0
    }
  }
}))

export const Error404 = makeStyles(theme => ({
  ...ShareStyle(theme)
}))