import React, { Component } from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import authService from "../api-authorization/AuthorizeService";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ForgotPassword as useStyles } from "./Styles";
import { Backdrop, RenderHeader, RenderMessage, RenderFooter, TextField, ErrorMessage, Button } from "../UI/Core";
import SendResetPasswordEmail from "./SendResetPasswordEmail";
import apiRequest from "../../utils/apiRequest";

class ForgotPassword extends Component {
  static displayName = ForgotPassword.name;
  
  constructor(props) {
    super(props);
    const params = queryString.parse(window.location.search);
    //console.log(params.src);
    this.recaptchaRef = React.createRef();
    this.state = {
      errors: {},
      message: {},
      src: params.src||'Unknown', /*{Unknown,Paris,CampaignAsia,CampaignChina,CampaignIndia,CampaignJapan,AsianInvestor,}*/
      email: '',
      disableSubmit: false,
      RecaptchaResponse: '',
      resend: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleValidation = this.handleValidation.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    const token = await authService.getAccessToken();
    if (token) window.location.href = "/manage";
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    // if (!this.handleValidation()) {
      //console.log(this.state);
      try {
        this.recaptchaRef.current.reset();
        const token = await this.recaptchaRef.current.executeAsync();
        //console.debug(token);
        this.setState({
          disableSubmit: true,
          errors: {},
          message: {},
          RecaptchaResponse: token
        });

        const resend_resp = await apiRequest.account.forgotpassword(this.state);
        //const resend_resp = await axios.post("./api/v1/account/forgotpassword", this.state);
        //this.recaptchaRef.current.reset();
        if (resend_resp.status === 202) {
          this.setState({
            disableSubmit: false,
            resend: true,
            RecaptchaResponse: '',
            errors: {}
          })
        }
      } catch (err) {
        this.setState({ disableSubmit: false });
        console.log(err);
        if (err.response.status === 400) {
          // if (error.response.data.error.params.email) {
          //   this.setState({
          //     disableSubmit: false,
          //     message: { emailInvalid: true }
          //   });
          // } else {
          //   this.setState({
          //     disableSubmit: false,
          //     message: { resendError: true }
          //   });
          // }
          const { error } = err.response.data;
          let errors = {};
          if (error.params) {
            Object.keys(error.params).forEach(function (key) {
              errors[key] = "errors:" + error.params[key][0];
            });
            this.setState({ errors: errors })
          }
        } else if (err.response.status === 500) {
          this.setState({
            message: { internalServerError: true }
          });
        }
      }
    // }
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }

  // handleValidation() {
  //   const { email } = this.state;
  //   let errors = {};
  //   if (email === "") {
  //     errors["email"] = "errors:is_required";
  //   } else if (!emailCheck(email)) {
  //     errors["email"] = "errors:invalid";
  //   }
  //   if (Object.keys(errors).length) {
  //     this.setState({ errors: errors });
  //     return true;
  //   } else
  //     return false;
  // }

  handleClose() {
    this.setState({ message: {} })
  }

  renderForgotPassword() {
    const { t, classes } = this.props;
    const { errors, message, email, disableSubmit } = this.state;
    const loginPath = ApplicationPaths.Login;
    const theme = "signup";

    // page data
    const headerData = {
      header: t("password:forgot_password"),
      caption: t("password:forgot_password_caption"),
      theme: theme
    }

    const messageData = [
      {
        open: message.emailInvalid,
        onClose: this.handleClose,
        severity: "error",
        children: t("errors:email_invalid")
      },
      {
        open: message.resendError,
        onClose: this.handleClose,
        severity: "error",
        children: t("errors:email_send_error")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const footerData = [
      <Button onClick={this.handleSubmit} fullWidth disabled={disableSubmit}>{t("common:submit")}</Button>,
      <Button color="secondary" component={Link} to={loginPath} fullWidth>{t("common:signin")}</Button>
    ]

    document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    return (
      <Container maxWidth="xs" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs>
              <TextField value={email} onChange={this.handleInputChange} id="email" name="email" label={t("profile:email") + " *"} error={Boolean(errors["email"])} disabled={disableSubmit} />
              {(errors["email"]) && <ErrorMessage>{t("profile:email")}{t(errors["email"])}</ErrorMessage>}
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} theme={theme} />

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          ref={this.recaptchaRef}
          size="invisible"
        />

      </Container>
    )
  }

  render() {
    const { resend, email } = this.state;

    if (resend) {
      return <SendResetPasswordEmail counter={resend} email={email} />
    } else {
      return this.renderForgotPassword();
    }
  }
}

export default withTranslation(["common", "errors", "password", "profile"])(withStyles(useStyles)(ForgotPassword));