import axios from 'axios';
import queryString from "query-string";
import authService from '../components/api-authorization/AuthorizeService'
const apiRoot = "/api/v1"
const legacyApiRoot = "/legacy/api/v1"
// handle 401: redirect to login page and get a new jwt token
axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401 
    && error.response.headers["www-authenticate"].startsWith("Bearer error=\"invalid_token\"")
  ) {
    var path = encodeURIComponent(window.location.href)
    var loginUrl = `${window.location.protocol}//${window.location.host}/authentication/login?returnUrl=${path}`;
    window.location.href = loginUrl;
    return;
 }
 throw error;
});
// append authorization header on every API request
axios.interceptors.request.use(async (request) => {
  request.headers.authorization = `Bearer ${ await authService.getAccessToken() }`;
  return request;
});
async function get(path, params) {
  const res = await axios.get(`${ apiRoot }/${ path }`, {
    params,
    paramsSerializer: params => {
      return queryString.stringify(params)
    }
  })
  //console.log(res.data);
  return res;
}
async function getLegacy(path, params) {
  const res = await axios.get(`${ legacyApiRoot }/${ path }`, {
    params,
    paramsSerializer: params => {
      return queryString.stringify(params)
    }
  })
  //console.log(res.data);
  return res;
}
async function deleteData(path, data) {
  const res = await axios.delete(`${ apiRoot }/${ path }`, {data:data});
  //console.log(res.data);
  return res;
}
async function post(path, payload) {
  const res = await axios.post(`${ apiRoot }/${ path }`, payload);
  //console.log(res);
  return res;
}
async function put(path, payload) {
  const res = await axios.put(`${ apiRoot }/${ path }`, payload);
  return res;
}
async function patch(path, payload) {
  const res = await axios.patch(`${ apiRoot }/${ path }`, payload);
  //console.log(res);
  return res;
}
export default{
    metadata:{
      list: async () => (await get("metadata"))
    },
    corporate:{
      list: async (data) => (await get("corporate-subscriptions",data)),
      detail: async (id) => (await get(`subscriptions/detail/${ id }`)),
      delete: async (data) => (await deleteData("subscriptions",data))
    },
    subscription: {
      list: async (data) => (await get("subscriptions",data)),
      detail: async (id) => (await get(`subscriptions/${ id }`)),
      delete: async (data) => (await deleteData("subscriptions",data)),
      legacy: async (data) => (await getLegacy("subscriptions",data)),
    },
    tenants: {
      list: async (data) => (await get("subscriptions/tenants",data)),
      preview: async (data) => (await post(`subscriptions/tenants/preview`,data)),
      myTenants: async (data) => (await get("tenants",data)),
      add: async (data) => (await post(`subscriptions/tenants/`,data)),
      delete: async (data) => (await deleteData("subscriptions/tenants/",data))
    },
    plan: {
      list: async (data) => (await get("plans", data))
    },
    checkout: {
      checkout: async (payload) => (await post(`checkouts`, payload)),
      preview: async (payload) => (await post(`checkouts/preview`, payload))
    },
    paymentMethod: {
      list: async () => (await get("account/payment-methods")), 
      delete: async (data) => (await deleteData("account/payment-methods",data)),
      setDefault: async (data)=>(await patch("account/payment-methods",data)),
      changePayment: async (data)=>(await patch("subscriptions/payment-methods",data)),
      add: async ()=>(await post("account/payment-methods"))
    },
    account:{
      profile: async (src) => (await get(`account/${ src }`)),
      updateProfile: async (data)=>(await patch("account/profile",data)),
      updateJob: async (data)=>(await patch("account/job",data)), 
      password: async (anonymous, data)=>(await patch(`account/password?anonymous=${ anonymous }`,data)),
      forgotpassword: async (data)=>(await post("account/forgotpassword",data)),  
    },
    workemails:{
      list: async () => (await get("workemails",{ type: ["Verified", "Inactive", "Pending"] })),
      delete: async (data) => (await deleteData("workemails",data)),
      add: async (data) => (await put("workemails",data))
    },
    address:{
      list: async () => (await get("account/billing")), 
      update: async (data) => (await post("account/billing",data))
    },
    newsletter:{
      list: async (data) => (await get("newsletters",data)), 
      listPlans: async (data) => (await get("newsletter-plans",data)), 
      update: async (data) => (await put("newsletters",data))
    },
    invoices:{
      list: async () => (await get("invoices")),
      update:  async () => (await patch("invoices")), 
      detail: async (id) => (await get(`invoices/${ id }`)),
    },
    userpendings:{
      submit:  async (data) => (await post("userpendings",data)), 
      update: async (data)=>(await patch("userpendings",data)),
      detailByToken: async (token) => (await get(`userpendings/getuserdetailbytoken/${ token }`)),
    },
    users:{
      submit: async (data) => (await post("users",data)), 
      getUserInfo: async (data) => (await get("getuserinfo",data)),
    }
}