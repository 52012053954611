import React from 'react';
import { Snackbar, Slide } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function (props) {
  const { open, onClose, severity, title, children, variant, ...rest } = props;

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={2500}
      TransitionComponent={Slide}
      onClose={onClose || {}}
      {...rest}
    >
      <Alert
        elevation={6}
        variant={variant || "filled"}
        onClose={onClose || {}}
        severity={severity || "success"}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </Alert>
    </Snackbar>
  )
}
