import React from "react";
import { withTranslation } from "react-i18next";
import { Container, Box, Grid } from "@material-ui/core";
import { Privacy as useStyles } from "./Styles";
import { RenderHeader } from "../UI/Core";

function Privacy(props) {
  const { t } = props;
  const classes = useStyles(props);
  const theme = "signup";

  const headerData = {
    header: t("common:privacy_policy"),
    theme: theme
  }

  return (
    <Container maxWidth="md" className={classes.root}>
      <RenderHeader {...headerData} />

      <Box component="section" className={classes.sectionRoot}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs>
            <Box className={classes.textArea} dangerouslySetInnerHTML={{ __html: t("html:privacy") }} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

Privacy.displayName = Privacy.name;

export default withTranslation(["common"])(Privacy);
