import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import { Footer as useStyles } from "./Styles";
import { useTranslation } from "react-i18next";

export default function (props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Container component='footer' maxWidth='xs' className={classes.root}>
      <Grid container spacing={1} justify='center'>
        <Grid item>{t("common:copyright")}</Grid>
        <Grid item>
          <Link to='/privacy' className={classes.link}>{t("common:privacy_policy")}</Link>{t("common:and")}<Link to='/terms' className={classes.link}>{t("common:terms_conditions")}</Link>
        </Grid>
      </Grid>
    </Container>
  )
}