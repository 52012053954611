import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import Dialog from "./Dialog";

RenderDialog.propTypes = {
  data: PropTypes.array.isRequired
}

export default function RenderDialog(props) {
  const { data } = props;

  return data ? (
    <Fragment>
      {data.map((item, key) => (
        <Dialog
          key={key}
          {...item}
          content={item.children || item.content || ""}
        />
      ))}
    </Fragment>
  ) : null
}