import React from "react";
import PropTypes from 'prop-types';
import { Box, Grid } from "@material-ui/core";
import { RenderFooter as useStyles } from "./Styles";

RenderFooter.propTypes = {
  data: PropTypes.array.isRequired,
  theme: PropTypes.string,
  fullWidthItems: PropTypes.bool 
}

export default function RenderFooter(props) {
  const { data, fullWidthItems } = props;
  const classes = useStyles(props);

  return data ? (
    <Box component="footer" className={classes.root}>
      <Grid container className={classes.container}>
        {data.map((item, key) => (
          item && (
            <Grid key={key} item xs={fullWidthItems ? 12 : true} className={classes.item}>
              {item}
            </Grid>
          )
        ))}
      </Grid>
    </Box>
  ) : null
}