import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withTranslation } from "react-i18next";
import InjectedAddNewPaymentForm from "../Payment/AddPaymentForm";
import clsx from "clsx";
import { Container, Box, Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Payment as PaymentIcon, Add as AddIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Backdrop, RenderHeader, RenderMessage, RenderSection, RenderPlanTable, RenderFooter, RenderSectionTitle, Typography, Select, Button } from "../UI/Core";
import { Paydue as useStyles, StripeElementsOptions } from "./Styles";
import { injectStripe } from 'react-stripe-elements';
import { isEmpty, tokenHeader, renewTokenHeader } from "../../utils/Utils";
import { StripeProvider, Elements } from "react-stripe-elements";
import apiRequest from "../../utils/apiRequest";

class Paydue extends Component {
  static displayName = Paydue.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      parentPath: (window.location.href.indexOf("bulk-subscriptions")>-1)?"/manage/bulk-subscriptions":"/manage/subscriptions",
      errors: {},
      token: {},
      message: {},
      selectedPaymentId: props.subscription.paymentMethodId || '',
      stripeSubscriptionId: props.subscription.id,
      items: 50,
      invoice: {},
      payment: {},
      payments: props.payments || [],
      disableSubmit: false,
      changePaymentMethod: false,
      addNewPayment: props.addNewPayment || false,
      setDefaultPayment: (props.payments.length === 0) ? true : false,
      stripeApiKey: process.env.REACT_APP_STRIPE_PUBLISHABLEKEY,
      invoiceDetails: undefined,
      paymentResult: {

        /*"stripeInvoiceId": "in_1HRatdEwLysLwiwKQrdKaKfV",
        "subscriptionStripeStatus": "active",
        "invoiceStripeStatus": "paid",
        "paymentIntentStripeStatus": "succeeded",
        "stripeSubscriptionId": "sub_I1eCvIbJ72hShC",
        "stripeInvoiceNumber": "577D2D70-0014"*/
      }
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.payInvoice = this.payInvoice.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.handleCancelAddNewPayment = this.handleCancelAddNewPayment.bind(this);
    this.handleShowAddNewPayment = this.handleShowAddNewPayment.bind(this);

    /* C#
      namespace Stripe
      {
          public static class SubscriptionStatuses
          {
            // no need
              public const string Trialing = "trialing";
            // no need
              public const string Active = "active";
              public const string PastDue = "past_due";
            // no need
              public const string Canceled = "canceled";
            // won't happen when pay by credit card
              public const string Unpaid = "unpaid";
              public const string Incomplete = "incomplete";
            // no need
              public const string IncompleteExpired = "incomplete_expired";
          }
      }
    */
  }

  async componentDidMount() {
    this._isMounted = true;
    const token = await tokenHeader();
    const {invoices, latestPayment, paymentMethod} = this.props;
    let invoice;
    if(latestPayment)
      invoice = invoices.find(o => o.stripeInvoiceId === latestPayment.stripeInvoiceId);
    else if(invoices.length>0)
      invoice = invoices[0];


    this.setState({ token: token, payment: paymentMethod, invoice: invoice, selectedPaymentId:paymentMethod.id });
/*     this.props.history.push({
      pathname: this.state.parentPath + "/paydue/" + this.props.stripeSubscriptionId,
      search: queryString.stringify(params)
    }) */
    this.getInvoice(invoice.id);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  async changePaymentMethod(_token) {
    const { selectedPaymentId, stripeSubscriptionId, token } = this.state;
    _token = (_token && _token.Authorization) ? _token : token;
    //console.log(selectedPaymentId, stripeSubscriptionId); 
    try {
      let res = await axios.patch("/api/v1/subscriptions/payment-methods", {
        paymentMethodId: selectedPaymentId,
        subscriptionId: stripeSubscriptionId, headers: _token
      });
      if (res.status === 200) {
        return;
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        _token = await renewTokenHeader();
        this.setState({ token: _token });
        this.changePaymentMethod(_token);
        // 402 check card
      } else if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { serverApiError: true },
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true },
        });
      }
    }
  }

  async payInvoice(_token) {
    const { subscription } = this.props;
    const { selectedPaymentId, invoice } = this.state;
    let res;

    _token = (_token && _token.Authorization) ? _token : this.state.token;
    this.setState({ disableSubmit: true });
    //console.log(subscription.paymentMethodId,selectedPaymentId);
    // if (subscription.paymentMethodId !== selectedPaymentId) {
    //   newPayment = payments.find(o=>o.id===selectedPaymentId);
    // }
    if (subscription.paymentMethodId !== selectedPaymentId) {
      await this.changePaymentMethod(_token);
    }
    //console.log(selectedPaymentId, payments);
    //return;
    try {
      res = await axios.patch('/api/v1/invoices', { headers: _token, 'invoiceId': invoice.id });
      //console.log(res);
      if (res.status === 201) {
        this.props.onComplete({ result: res.data.result, paymentIntent: {}, selectedPaymentId:selectedPaymentId  });
        if(this._isMounted)
          this.setState({ disableSubmit: false});
      } else if (res.status === 200) {
        //console.log(res.data.result);
        await this.stripePay(res.data.result);
        //this.props.onComplete({ result: res.data.result, paymentIntent: {}, selectedPaymentId:selectedPaymentId });
      }

    } catch (err) {
      if (err.response.status === 401) {
        _token = await renewTokenHeader();
        this.payInvoice(_token);
        this.setState({ token: _token })
      } else if (err.response.status === 402) {
        this.props.onComplete({ result: err.response.data.result });
      } else if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {

        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async stripePay(result) {
    const { stripe } = this.props;
    const { selectedPaymentId } = this.state;
    const { clientSecret } = result;
    //console.log('stripePay');
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret);
      //console.log(result, error, paymentIntent);
      /* error
        charge: "ch_1HPlJUEwLysLwiwKEGZmlhSO"
        code: "card_declined"
        decline_code: "insufficient_funds"
        doc_url: "https://stripe.com/docs/error-codes/card-declined"
        message: "Your card has insufficient funds."
        payment_intent: {id: "pi_1HPlJ3EwLysLwiwK5xfGnShc", object: "payment_intent", amount: 700, canceled_at: null, cancellation_reason: null, …}
        payment_method: {id: "pm_1HPl3KEwLysLwiwKOZ5v2PHi", object: "payment_method", billing_details: {…}, card: {…}, created: 1599726166, …}
        type: "card_error"

        result
        clientSecret: "pi_1HPlJ3EwLysLwiwK5xfGnShc_secret_WRuvHHtIfiG44gRtV4xBBz9DZ"
        invoiceStripeStatus: "open"
        paymentIntentStripeStatus: "requires_action"
        stripeInvoiceId: "in_1HPlJ3EwLysLwiwKBjKaQQkp"
        stripeInvoiceNumber: "577D2D70-0003"
        stripeSubscriptionId: "sub_Hzkou6z4ecVRH5"
        subscriptionStripeStatus: "incomplete"
      */
      if (error) {
        // let errors = await stripeErrorsCheck(error);
/*        this.setState({
          disableSubmit: false,
          paymentResult: result
        });*/
        this.props.onComplete({result: result});
      } else if(paymentIntent.status === "succeeded") {
        this.props.onComplete({ result: result, paymentIntent: paymentIntent, selectedPaymentId:selectedPaymentId });
      } else if(result.paymentIntentStripeStatus==="succeeded"){
        this.props.onComplete({ result: result, paymentIntent: {}, selectedPaymentId:selectedPaymentId });
      }
    } catch (err) {
      //alert(err);

      if (err.response.status === 400) {
        this.setState({
          disableSubmit: false,
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          disableSubmit: false,
          message: { internalServerError: true }
        });
      }
    }
  }

  async getInvoice(id) {
    try {
      const res = await apiRequest.invoices.detail(id);
      if (res.status === 200) {
        //console.log(res.data.result);
        this.setState({ invoiceDetails: res.data.result });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden
        console.log(err.response.data.error.message);
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }

  handleCancelAddNewPayment(e) {
    //e.preventDefault();
    this.props.onReloadPayment();
    this.setState({ addNewPayment: false });
    this.props.history.goBack();
  }

  handleShowAddNewPayment(){
    const {stripeSubscriptionId, parentPath} = this.state;
    this.setState({ addNewPayment: true });
    //console.log(`${parentPath}/paydue/add-payment/${stripeSubscriptionId}`);
    this.props.history.push({ pathname: `${parentPath}/paydue/add-payment/${stripeSubscriptionId}` });
  }

  handleClose() {
    this.setState({ message: {} })
  }
  static getDerivedStateFromProps(props, state) {
    if (props.payments !== state.payments) {
      return { payments: props.payments };
    }

    if(props.addNewPayment!==state.addNewPayment)
      return {addNewPayment:props.addNewPayment};

    return null;
  }

  render() {
    const { t, i18n, classes } = this.props;
    const { message, selectedPaymentId, invoice, payment, stripeApiKey, addNewPayment, payments, changePaymentMethod, disableSubmit, invoiceDetails } = this.state;
    const { subscription, latestPayment, setDefaultPayment } = this.props;
    const locale = (i18n.language === "zh-CN") ? "zh" : i18n.language;

    // page data
    const headerData = subscription.stripeStatus === "incomplete" ? {
      header: t("payment:pay_invoice"),
      caption: t('payment:pay_invoice_caption')
    } : subscription.stripeStatus === "past_due" ? {
      header: t("payment:pay_due_invoice"),
      caption: t('payment:pay_due_invoice_caption')
    } : {
          header: t("payment:invoice")
        }

    const messageData = [
      // {
      //   open: message.stripeErrors,
      //   onClose: this.handleClose,
      //   severity: "error",
      //   title: t(message.errorsTitle),
      //   children: t(message.errorsMessage)
      // },
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const sectionData = [
      <Typography variant="h5" className={classes.refNo}>{t("payment:invoice_number") + ": " + invoice.stripeInvoiceNumber}</Typography>
    ]

    const remarkData = [
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("payment:subscription_terms", { link: "/terms", className: classes.link }) }} className={classes.remark} />
    ]

    const footerData = [
      <Button color="secondary" onClick={() => this.props.onCancel()} fullWidth >{t("common:back")}</Button>,
      (subscription.stripeStatus === 'incomplete' || subscription.stripeStatus === 'past_due') && <Button color="third" startIcon={<PaymentIcon />} onClick={this.payInvoice} fullWidth >{t("payment:pay")}</Button>
    ]

    //console.log(invoice, payment, addNewPayment);

    if (!addNewPayment) {
      return (
        <Container maxWidth="md" className={classes.root}>
          <Backdrop variant="processing" open={disableSubmit} />
          <RenderHeader {...headerData} />

          {!isEmpty(latestPayment.paymentError) ?
            <Fragment>
              <Typography variant="body1" gutterBottom={true}><strong>{t('payment:Lastest Record')}</strong></Typography>
              <Alert severity="warning" classes={{ message: classes.alertMessage }}>
                <AlertTitle>{t('stripe_errors:' + latestPayment.paymentError.code)}</AlertTitle>
                {latestPayment.paymentError.declineCode ? t('stripe_errors:' + latestPayment.paymentError.declineCode) : t('stripe_errors:generic_decline')}
              </Alert>
              </Fragment>
            : <Fragment>
              <Alert severity="warning" classes={{ message: classes.alertMessage }}>
                <AlertTitle>{t("payment:payment_fails")}</AlertTitle>
                {t("payment:payment_fails_caption")}
              </Alert>
            </Fragment>
          }

          <RenderMessage data={messageData} />
          <RenderSection data={sectionData} />
          <RenderPlanTable invoice={invoice} {...invoiceDetails} />

          {/* <RenderPayment /> */}

          <Box component="section" className={classes.sectionRoot}>
            {!isEmpty(latestPayment) && latestPayment.paymentIntentStripeStatus === "requires_action" && !changePaymentMethod && payment ?
              <Fragment>
                <RenderSectionTitle data={t("payment:payment_method")} />
                <Grid container className={clsx(classes.gridContainer, classes.paymentContainer)}>
                  <Grid item xs={12} className={classes.mdNoWidth}>
                    <Typography variant="h6">{payment.brand} **** **** **** {payment.last4}</Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Typography variant="body1" className={classes.or}>{t("common:or")}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button color="secondary" onClick={() => this.setState({ changePaymentMethod: true })}>{t("subscription:change_payment_method")}</Button>
                  </Grid>
                </Grid>
              </Fragment>
              : !isEmpty(latestPayment) && latestPayment.paymentIntentStripeStatus !== "successed" &&
              <Grid container className={clsx(classes.gridContainer, classes.paymentContainer)}>
                {payments && !isEmpty(payments) &&
                  <Grid item xs={12} md={6}>
                    <Select value={selectedPaymentId} onChange={this.handleInputChange} name="selectedPaymentId" label={t("payment:payment_method")} >
                      {payments.map(payment => <option key={payment.paymentMethodId} value={payment.id}>{payment.brand} **** **** **** {payment.last4} {payment.isDefault && `(${t("payment:default_payment")})`}</option>)}
                    </Select>
                  </Grid>
                }

                <Grid item xs={12} md={6}>
                  <Button color="secondary" startIcon={<AddIcon />} onClick={this.handleShowAddNewPayment}>{t("payment:add_new_payment_method")}</Button>
                </Grid>
              </Grid>
            }
          </Box>

          <RenderSection data={remarkData} />
          <RenderFooter data={footerData} />
        </Container>
      )
    } else {
      return (
        <StripeProvider apiKey={stripeApiKey}>
          <Elements key={locale} fonts={StripeElementsOptions.fonts} locale={locale}>
            <InjectedAddNewPaymentForm setDefault={setDefaultPayment} handleCancel={this.handleCancelAddNewPayment} />
          </Elements>
        </StripeProvider>
      );
    }
  }
}

export default withTranslation(["common", "payment", "subscription", 'errors'])(injectStripe(withStyles(useStyles)(Paydue)));