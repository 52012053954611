import { defaultTheme, ShareIconStyle } from "../../themes/theme";
import ColumnGrid from "../../themes/ColumnGrid";

export { StripeElementsOptions } from "../Payment/Styles";

const ShareStyle = theme => {
  const { root, margin, spacing } = defaultTheme;

  return {
    root: {
      margin: theme.spacing(root.top, 0, root.bottom)
    },
    sectionRoot: {
      margin: theme.spacing(margin, 0)
    },
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    },
    empty: {
      padding: 0
    },
    overlay: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: 1101,
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    error: {
      margin: theme.spacing(margin, 0)
    },
    navigateBeforeRoot: {
      padding: 0
    },
    navigateBeforeIcon: {
      // fontSize: theme.typography.h4.fontSize
      fontSize: "2.5625rem"
    },
    remark: {
      fontSize: "0.75rem"
    },
    link: {
      color: theme.palette.text.primary,

      '&:hover': {
        color: theme.palette.action.hover
      }
    }
  }
}

export const Dashboard = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing * 1.5 }),
      marginTop: theme.spacing(spacing / 2),
      alignItems: "center"
    },
    gridBorder: {
      [theme.breakpoints.up("md")]: {
        borderLeft: `dashed ${theme.palette.border.primary} 1px`
      }
    },
    brandGridContainer: {
      // ...ColumnGrid({ theme: theme, argument: spacing }),
      marginTop: "0.5rem",
      columnGap: "1rem",
      rowGap: "0.5rem"
    },
    item: {
      padding: 0,
      "& a": {
        display: "block"
      }
    },
    logo: {
      display: "block"
      // width: "100%",
      // maxWidth: 130
    },
    aiLogo: {
      width: 150
    },
    capLogo: {
      width: 120
    },
    faLogo: {
      width: 133
    },
    ctLogo:{
      marginTop: 4,
      width: 210
    }
  }
}

export const YourSubscriptions = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    root: {},
    header: {
      fontWeight: "bold"
    },
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing }),
      marginTop: theme.spacing(spacing / 2)
    },
    item: {
      textAlign: "center"
    }
  }
}

export const JoinSubscriptions = theme => {
  return {
    ...ShareStyle(theme),
    sectionRoot: {
      margin: 0,
      borderRadius: 8
    },
    title: {
      fontWeight: "bold",
      whiteSpace: "pre-wrap"
    },

  }
}

export const Profile = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    workEmailContainer: {
      padding: theme.spacing(1, 0),
      borderBottom: `solid 1px ${theme.palette.border.primary}`
    },
    workEmailRow: {
      ...ColumnGrid({ theme: theme, argument: spacing / 2, horiz: true }),
      alignItems: "center",
    },
    workEmailIconItem: {
      lineHeight: 1
    },
    workEmailValueItem: {
    },
    workEmailFeatureItem: {
      textAlign: "right"
    },
    workEmailValue: {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    workEmailIcon: {
      fontSize: "1.5rem",
    },
    success: {
      color: theme.palette.success.main
    },
    workEmailButton: {}
  }
}

export const EditInfo = theme => ({
  ...ShareStyle(theme)
})

export const WorkEmail = theme => ({
  ...ShareStyle(theme)
})

export const Subscriptions = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    ...ShareIconStyle(theme),
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing }),
      marginTop: theme.spacing(spacing / 2)
    },
    item: {
      textAlign: "center"
    },
    gridBorder: {
      borderLeft: `dashed ${theme.palette.border.primary} 1px`
    }
  }
}

export const SubscriptionDetail = theme => {

  return {
    ...ShareStyle(theme),
    ...ShareIconStyle(theme),
    statusIcon: {
      position: "absolute",
      top: "50%",
      transform: "translate(-125%, -50%)",
    },
    filter: {
      fontSize: "0.875rem",
      textTransform: "none"
    },
    menu: {},
    menuItem: {
      fontSize: "0.875rem"
    },
    cardGrid: {
      textDecoration: "none"
    },
    card: {},
    cardHover: {
      transition: "all 0.15s ease",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: (theme.palette.type === "light") ? theme.palette.grey["300"] : theme.palette.grey["700"],
        "& button": {
          backgroundColor: theme.palette.warning.dark
        }
      }
    },
    cardHeader: {
      position: "relative",
      paddingBottom: `${theme.spacing(2)}px !important`
    },
    cardContent: {
      paddingTop: 0,
      "&:last-child": {
        paddingBottom: theme.spacing(2)
      }
    },
    invoiceStatus: {
      [theme.breakpoints.up(720)]: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1,
        padding: 20
      }
    },
    invoiceHeader: {
      fontWeight: "bold"
    },
    alertMessage: {
      flexGrow: 1
    }
  }
}

export const LegacySubscriptions = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    ...ShareIconStyle(theme),
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing, horiz: true }),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    th: {
      borderTop: `solid 1px ${theme.palette.border.primary}`,
      borderBottom: `solid 1px ${theme.palette.border.primary}`
    },
    title: {
      fontWeight: "bold"
    },
    period: {
      [theme.breakpoints.up("md")]: {
        textAlign: "center"
      }
    },
    status: {
      [theme.breakpoints.up("md")]: {
        textAlign: "right"
      }
    },
    item: {
      textAlign: "center"
    }
  }
}

export const ChangePayment = theme => ({
  ...ShareStyle(theme)
})

export const Paydue = theme => {

  return {
    ...ShareStyle(theme),
    refNo: {
      fontWeight: "bold"
    },
    paymentContainer: {
      alignItems: "center"
    },
    mdNoWidth: {
      [theme.breakpoints.up("md")]: {
        flexGrow: 0,
        maxWidth: "none",
        flexBasis: "auto"
      }
    },
    or: {
      [theme.breakpoints.up("md")]: {
        textAlign: "center"
      }
    }
  }
}

export const PaymentComplete = theme => {

  return {
    ...ShareStyle(theme),
    ...ShareIconStyle(theme),
    refNo: {
      fontWeight: "bold"
    }
  }
}

export const Payment = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    item: {
      textAlign: "center"
    },
    box: {
      borderBottom: `solid 1px ${theme.palette.border.primary}`,
      padding: theme.spacing(spacing, 0)
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: spacing / 2 }),
      display: "flex",
      alignItems: "center",
      textAlign: "left"
    },
    valueItem: {
      [theme.breakpoints.up("sm")]: {
        textAlign: "right"
      }
    },
    featureContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing / 2 }),
      display: "inline-flex",
      justifyContent: "flex-end",
      width: "auto"
    }
  }
}

export const Addresses = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing }),
      marginTop: theme.spacing(spacing / 2)
    },
    item: {
      textAlign: "center"
    }
  }
}

export const EditAddresses = theme => ({
  ...ShareStyle(theme)
})

export const SubscribeEmails = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing * 4 })
    },
    name: {
      fontWeight: "bold",
      lineHeight: 1.25
    },
    switch: {
      alignItems: "stretch"
    }
  }
}

export const Password = theme => ({
  ...ShareStyle(theme)
})

export const Licenses = theme => {
  const { spacing } = defaultTheme;

  return {
    ...ShareStyle(theme),
    addGridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing, horiz: true }),
      marginTop: theme.spacing(spacing),
      marginBottom: theme.spacing(spacing)
    },
    confirmGridContainer: {
      width: "100%",
      margin: theme.spacing(spacing, 0),
      padding: theme.spacing(spacing),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4
    },
    paper: {
      width: "100%",
      maxWidth: 600,
    },
    title: {
      flex: "1 1 100%"
    },
    invalids: {
      color: theme.palette.error.main
    },
    duplicates: {
      color: theme.palette.warning.main
    }
  }
}