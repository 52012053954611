import React, { Component } from "react";
import  { Redirect } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import { Container, Box, Grid, Link as MuiLink } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { VerifyWorkEmail as useStyles } from "./Styles";
import { RenderHeader, RenderFooter, Typography, Button } from "../UI/Core";

class VerifyWorkEmail extends Component {
  static displayName = VerifyWorkEmail.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.timer = 0;
    const params = queryString.parse(this.props.location.search);
    this.state = {
      error: false,
      status: params.status || '', // success, failed
      reason: params.reason || '', // unknown, verified, invalid_token, email_in_use 
      returnurl: (params.returnurl && params.status==='success' && params.returnurl!=='')?`${decodeURIComponent(params.returnurl)}`:'/manage/',
      counter: process.env.REACT_APP_REDIRECT,
      buttonName: '',
      showCounter: (params.status!=='success')?false:true,
      redirect: false
    };
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  componentWillUnmount(){
    clearInterval(this.timer);
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    let reasons = ['unknown', 'verified', 'invalid_token', 'email_in_use'],
      statuses = ['success', 'failed'];
    const { status, reason } = this.state;
    //console.log(status, reason,(reasons.indexOf(reason)===-1),(statuses.indexOf(status)===-1));
    if ((reasons.indexOf(reason) === -1) || (statuses.indexOf(status) === -1))
      window.location.href = '/';
  }

  startTimer() {
    if (this.timer===0 && this.state.counter > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let seconds = this.state.counter - 1;
    this.setState({ counter: seconds });
    if (seconds === 0 && this._isMounted) {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState({
        showCounter: false,
        redirect:true
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    const { returnurl, status, reason, counter, showCounter, redirect} = this.state;
    const externalLink = (returnurl.indexOf("http://") === 0 || returnurl.indexOf("https://") === 0)?true:false;
    const theme = "signup";

    const headerData = {
      header: t("profile:work_email_verified_" + status),
      theme: theme
    }
    const content = t("profile:work_email_reason_" + reason);

    const footerData = [
      (externalLink && showCounter)?
        <Button fullWidth component={'a'} href={returnurl}>{t("subscription:back_to_article")}</Button>
        : (showCounter)?
          <Button fullWidth component={Link} to={returnurl}>{t("dashboard:dashboard")}</Button>
          : <Button fullWidth component={Link} to={'/manage/profile'}>{t("profile:add_work_email")}</Button>
      ];

    document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    if(status==='success')
      this.startTimer();

    if(redirect && status==='success'){
      if (externalLink) {
        window.location.href = returnurl;
        return (
          <Typography variant="body1" component="p" >
            {t("common:redirect_now")}
          </Typography>
        ); 
      }else{
        return (<Redirect to={returnurl}/>);
      }
        
    }else{
      return (
        <Container maxWidth="xs" className={classes.root}>
          <RenderHeader {...headerData} />

          <Box component="section" className={classes.sectionRoot}>
            <Grid container spacing={2} className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p">{content}</Typography>
              </Grid>
              <Grid item xs={12} >
                <Typography dangerouslySetInnerHTML={{ __html: t("profile:work_email_caption") }} variant="body1" component="p" />
              </Grid>
              {showCounter &&
                <Grid item xs={12} >
                  <Typography variant="body1" component="p" >
                    {externalLink ?
                      <MuiLink component={'a'} href={returnurl}>{t("common:redirect_one")}{`${counter}`}{t("common:redirect_two")}</MuiLink>
                      : <MuiLink component={Link} to={returnurl}>{t("common:redirect_one")}{`${counter}`}{t("common:redirect_two")}</MuiLink>
                    }
                  </Typography>
                </Grid>
              }
            </Grid>
          </Box>

          <RenderFooter data={footerData} theme={theme} />
        </Container>
      )
    }
  }
}

export default withTranslation(["common", "errors", "profile", "email_validation", "dashboard"])(withStyles(useStyles)(VerifyWorkEmail));