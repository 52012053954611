import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { EmailValidation as useStyles } from "./Styles";
import InvalidEmail from "./InvalidEmail";
import { Backdrop, RenderHeader, RenderFooter, RenderMessage, Typography, Button } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";

class EmailValidation extends Component {
  static displayName = EmailValidation.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.timer = 0;
    this.state = {
      disableSubmit: false,
      disableResend: false,
      email: this.props.email,
      RecaptchaResponse: '',
      error: false,
      message: {},
      counter: process.env.REACT_APP_WAITING,
      showCounter: false
    };

    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() { 
    this._isMounted = true;
  }
  componentWillUnmount(){
    clearInterval(this.timer);
    this._isMounted = false;
  }
  handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      this.recaptchaRef.current.reset();
      const token = await this.recaptchaRef.current.executeAsync();
      this.setState({
        disableSubmit: true,
        message: {},
        RecaptchaResponse: token
      });
      const resend_resp = await apiRequest.userpendings.update({
        email: this.props.email,
        RecaptchaResponse: token
      });

      if (resend_resp.status === 202) {
        this.setState({
          disableSubmit: false,
          disableResend: true,
          showCounter: true,
          message: { sentSuccessfully: true }
        });
        this.startTimer();
      }
    } catch (error) {
      this.setState({ disableSubmit: false });
      if (error.response.status === 400) {
        if (error.response.data.error.params.email) {
          this.setState({
            disableResend: true,
            showCounter: false,
            message: { emailInvalid: true }
          });
        } else {
          this.setState({
            disableResend: true,
            showCounter: false,
            message: { sendError: true }
          });
        }
      }
      else if (error.response.status === 500) {
        this.setState({
          disableResend: false,
          showCounter: false,
          message: { internalServerError: true }
        });
      }
    }
  }

  startTimer() {
    if (this.timer === 0 && this.state.counter > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let seconds = this.state.counter - 1;
    this.setState({ counter: seconds });
    if (seconds === 0  && this._isMounted) {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState({
        counter: process.env.REACT_APP_WAITING,
        showCounter: false,
        disableResend: false,
      });
    }
  }

  handleClose() {
    this.setState({ message: {} })
  }

  renderResend() {
    const { t, classes } = this.props;
    const { message, disableSubmit, disableResend, showCounter, counter, email } = this.state;
    const theme = "signup";

    // page data
    const headerData = {
      header: t("email_validation:email_validation"),
      theme: theme
    }

    const messageData = [
      {
        open: message.sentSuccessfully,
        onClose: this.handleClose,
        severity: "success",
        children: t("email_validation:email_sent_successfully")
      },
      {
        open: message.emailInvalid,
        onClose: this.handleClose,
        severity: "error",
        children: t("errors:invalid_email_address")
      },
      {
        open: message.sendError,
        onClose: this.handleClose,
        severity: "error",
        children: t("errors:email_send_error")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const footerData = [
      <Button fullWidth disabled={(disableSubmit || disableResend)} onClick={this.handleSubmit}>{t("common:resend")} {showCounter && "(" + counter + ")"}</Button>
    ]

    document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    return (
      <Container maxWidth="xs" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs>
              <div>
                <Typography dangerouslySetInnerHTML={{ __html: t("email_validation:email_validation_content")}} variant="body1" component="span"/>
                <Typography variant="body1" component="span">{email}.</Typography>
                <Typography dangerouslySetInnerHTML={{ __html: t("email_validation:email_validation_content_two")}} variant="body1" component="span"/>
              </div>
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} theme={theme} />


        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          ref={this.recaptchaRef}
          size="invisible"
        />

      </Container>
    )
  }

  render() {
    const { t } = this.props;
    const { email } = this.state;
    const theme = "signup";

    const footerData = [
      <Button component={Link} to={"/signup"} fullWidth>{t("common:signup")}</Button>
    ]

    if (email) {
      return this.renderResend();
    } else {
      return <InvalidEmail footerData={footerData} theme={theme} />
    }
  }
}

export default withTranslation(["common", "errors", "email_validation"])(withStyles(useStyles)(EmailValidation));