import { defaultTheme, ShareIconStyle } from "../../themes/theme";
import PropTypes from 'prop-types';
import ColumnGrid from "../../themes/ColumnGrid";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const defaultRenderHeader = theme => {
  const { root, margin } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      margin: theme.spacing(root.top, 0, margin)
    },
    container: {},
    headerBeforeItem: {},
    headerItem: {},
    captionItem: {},
    header: {
      textTransform: "capitalize"
    },
    caption: {},
  }))(theme)
}

const signupRenderHeader = theme => {
  // const { root, margin } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(4),

      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(10)
      }
    },
    header: {
      textAlign: "center",
      textTransform: "capitalize"
    },
    caption: {
      textAlign: "center"
    },
  }))(theme)
}

const RenderHeaderStylesheets = {
  default: defaultRenderHeader,
  signup: signupRenderHeader
}

export const RenderHeader = props =>
  props.theme ? RenderHeaderStylesheets[props.theme](props)
    : RenderHeaderStylesheets.default(props)

RenderHeader.propTypes = {
  theme: PropTypes.string
}

const defaultRenderFooter = theme => {
  const { margin, spacing } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      margin: theme.spacing(margin, 0),
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: spacing }),
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-start",
      }
    },
    item: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: 232,
        flexBasis: 194,
      }
    }
  }))(theme)
}

const signupRenderFooter = theme => {
  const { margin, spacing } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      margin: theme.spacing(margin, 0),
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: spacing }),
      justifyContent: "center"
    },
    item: {}
  }))(theme)
}

const RenderFooterStylesheets = {
  default: defaultRenderFooter,
  signup: signupRenderFooter
}

export const RenderFooter = props =>
  props.theme ? RenderFooterStylesheets[props.theme](props)
    : RenderFooterStylesheets.default(props)

RenderFooter.propTypes = {
  theme: PropTypes.string
}

export const RenderSection = theme => {
  const { margin, spacing } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      margin: theme.spacing(margin, 0),
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: spacing }),
    },
    item: {}
  }))(theme)
}

export const RenderFeatureButton = theme => {
  const { margin, spacing } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      margin: theme.spacing(margin, 0)
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    }
  }))(theme)
}

export const RenderSectionTitleWithFeature = theme => {
  const { spacing } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    },
    titleItem: {},
    valueItem: {
      textAlign: "right"
    },
    header: {
      fontWeight: "bold",
      textTransform: "capitalize"
    }
  }))(theme)
}

export const RenderSectionTitle = theme => {
  const { spacing } = defaultTheme;

  return makeStyles(theme => ({
    root: {},
    gutters: {
      margin: theme.spacing(spacing, 0, spacing)
    },
    header: {
      fontWeight: "bold",
      textTransform: "capitalize"
    },
    center: {
      fontWeight: "normal",
      textAlign: "center"
    }
  }))(theme)
}

export const RenderDisplaySection = theme => {
  const { padding, spacing } = defaultTheme;

  return makeStyles(theme => ({
    root: {},
    container: {
      padding: theme.spacing(padding, 0),
      borderBottom: `solid 1px ${theme.palette.border.primary}`
    },
    row: {
      flexWrap: "nowrap",
      ...ColumnGrid({ theme: theme, argument: spacing })
    },
    titleItem: {
      position: "relative"
    },
    valueItem: {
      position: "relative",
      textAlign: "right"
    },
    title: {},
    value: {}
  }))(theme)
}

export const RenderAddress = theme => {
  const { margin } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      margin: theme.spacing(margin, 0)
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: margin })
    }
  }))(theme)
}

export const RenderPlanTable = theme => {
  const { spacing, margin } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      margin: theme.spacing(margin * 2, 0)
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    },
    title: {
      fontWeight: "700",
      textTransform: "capitalize"
    },
    body: {
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.h6.fontSize,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.h5.fontSize,
      }
    },
    end: {
      textAlign: "right"
    },
    currency: {
      textTransform: "uppercase"
    },
    padding: {
      paddingTop: 6,
      paddingBottom: 6
    },
    borderTop: {
      borderTop: "solid 1px " + theme.palette.border.primary,
    },
    border: {
      borderTop: "solid 1px " + theme.palette.border.primary,
      // borderBottom: "solid 1px " + theme.palette.border.primary
    },
  }))(theme)
}

export const RenderPaymentMethod = theme => {
  const { spacing, margin } = defaultTheme;

  return makeStyles(theme => ({
    root: {
      margin: theme.spacing(margin, 0)
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: spacing, horiz: true })
    }
  }))(theme)
}

export const RenderPaymentStatusHeader = theme => {
  return makeStyles(theme => ({
    root: {
      borderRadius: 0
    },
    icon: {
      fontSize: "5rem"
    },
    title: {
      textTransform: "capitalize"
    }
  }))(theme)
}

export const RenderSubscription = theme => {
  return makeStyles(theme => ({
    ...ShareIconStyle(theme),
    item: {
      textAlign: "center"
    },
    card: {
      display: "flex",
      flexFlow: "column wrap",
      textDecoration: "none",
      textAlign: "left",
      transition: "all 0.15s ease",
      backgroundColor: (theme.palette.type === "light") ? theme.palette.grey["300"] : theme.palette.grey["900"],
      [theme.breakpoints.up("md")]: {
        flexFlow: "row nowrap",
        alignItems: "center",
      },
      "&:hover": {
        backgroundColor: (theme.palette.type === "light") ? theme.palette.grey["A100"] : theme.palette.grey["800"]
      }
    },
    content: {
      "&:first-child": {
        display: "none",
        textAlign: "center",
        padding: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          display: "block"
        }
      },
      "&:last-child": {
        padding: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(2, 2, 2, 0)
        }
      }
    },
    icon: {
      display: "block",
      width: 36,
      margin: "auto",
      fontSize: 36,
    },
    title: {
      fontWeight: "bold",
      lineHeight: 1.25
    },
    box: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    boxIcon: {
      display: "inline-block",
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    }
  }))(theme)
}

export const RenderTenant = theme => {
  return makeStyles(theme => ({
    ...ShareIconStyle(theme),
    item: {
      textAlign: "center"
    },
    card: {
      display: "flex",
      flexFlow: "column wrap",
      textDecoration: "none",
      textAlign: "left",
      transition: "all 0.15s ease",
      [theme.breakpoints.up("md")]: {
        flexFlow: "row nowrap",
        alignItems: "center",
      }
    },
    content: {
      "&:first-child": {
        display: "none",
        textAlign: "center",
        padding: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          display: "block"
        }
      },
      "&:last-child": {
        padding: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(2, 2, 2, 0)
        }
      }
    },
    icon: {
      display: "block",
      width: 36,
      margin: "auto",
      fontSize: 36,
    },
    title: {
      fontWeight: "bold",
      lineHeight: 1.25
    },
    box: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    boxIcon: {
      display: "inline-block",
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    }
  }))(theme)
}

export const RenderTenantSm = theme => {
  return makeStyles(theme => ({
    ...ShareIconStyle(theme),
    item: {
      textAlign: "center"
    },
    card: {
      display: "flex",
      flexFlow: "column wrap",
      textDecoration: "none",
      textAlign: "left",
      transition: "all 0.15s ease",
      [theme.breakpoints.up("md")]: {
        flexFlow: "row nowrap",
        alignItems: "center",
      }
    },
    content: {
      "&:first-child": {
        display: "none",
        textAlign: "center",
        padding: theme.spacing(2)
      },
      "&:last-child": {
        padding: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          fontSize: 16
        }
      }
    },
    icon: {
      display: "block",
      width: 36,
      margin: "auto",
      fontSize: 36,
    },
    title: {
      fontWeight: "bold",
      lineHeight: 1.25,
      [theme.breakpoints.up("md")]: {
        fontSize: 16
      }
    },
    box: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    boxIcon: {
      display: "inline-block",
      marginRight: theme.spacing(0.5)
    }
  }))(theme)
}

export const Dialog = theme => {
  const { spacing } = defaultTheme;

  return makeStyles(theme => ({
    paper: {
      borderRadius: theme.spacing(1)
    },
    success: {
      backgroundColor: theme.palette.success.main
    },
    warning: {
      backgroundColor: theme.palette.warning.main
    },
    info: {
      backgroundColor: theme.palette.info.main
    },
    error: {
      backgroundColor: theme.palette.error.main
    },
    title: {
      textTransform: "capitalize"
    },
    titleSeverity: {
      "& .MuiTypography-root": {
        color: theme.palette.common.white
      }
    },
    textSeverity: {
      color: theme.palette.common.white
    },
    container: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    },
    button: {
      minWidth: 0,
      backgroundColor: fade(theme.palette.common.white, 0.07)
    },
    buttonSeverity: {
      color: theme.palette.common.white,
      backgroundColor: fade(theme.palette.common.white, 0.17),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.3)
      }
    }
  }))(theme)
}

const ShareStyle = theme => {
  const { margin, spacing } = defaultTheme;

  return {
    root: {},
    sectionRoot: {
      margin: theme.spacing(margin, 0)
    },
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    },
    overlay: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: 1101,
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    remarks: {
      marginLeft: theme.spacing(1.75),
      marginRight: theme.spacing(1.75)
    }
  }
}

export const Message = theme => {
  return makeStyles(theme => ({
    ...ShareStyle(theme)
  }))(theme)
}

export const RenderPage = theme => {
  return makeStyles(theme => ({
    ...ShareStyle(theme)
  }))(theme)
}