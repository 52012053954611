import React from "react";
import PropTypes from 'prop-types';
import { Box, Typography } from "@material-ui/core";
import { RenderSectionTitle as useStyles } from "./Styles";
import clsx from "clsx";

RenderSectionTitle.propTypes = {
  data: PropTypes.string.isRequired,
  gutters: PropTypes.bool
}

export default function RenderSectionTitle(props) {
  const { data, gutters, center } = props;
  const classes = useStyles(props);

  return data ? (
    <Box
      component="header"
      className={clsx(
        classes.root,
        gutters && classes.gutters,
      )}
    >
      <Typography
        variant="body1"
        className={clsx(
          classes.header,
          center && classes.center,
        )}>
        {data}
      </Typography>
    </Box>
  ) : null
}