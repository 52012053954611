import React, { Component, Fragment } from 'react';
import { withTranslation } from "react-i18next";
import { GatewayContext } from "../../utils/GatewayContext";
import { isEmpty } from '../../utils/Utils';
import { Container, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Edit as EditIcon } from '@material-ui/icons';
import { Addresses as useStyles } from "./Styles";
//import EditAddresses from "./EditAddresses";
import Address from "../Payment/Address";
import { Backdrop, RenderHeader, RenderMessage, RenderFeatureButton, RenderSectionTitle, RenderDisplaySection, IconLabelButton } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";

class Addresses extends Component {
  static displayName = Addresses.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    
    this.state = {
      userAddress: {
        "phone": {
          countryCode: "",//HK
          normalizedPhoneNumber: "", //+85264321325
          phoneNumber: "",//64321325
          prefix: 0, //852
        },
        "billingAddress": {
          "city": "",
          "countryCode": "",
          "line1": "",
          "line2": "",
          "postalCode": "",
          "state": ""
        },
        "shipToBillingAddress": false,
        "shippingAddress": {
          "name": "",
          "phone": {
            countryCode: "",//HK
            normalizedPhoneNumber: "", //+85264321325
            phoneNumber: "",//64321325
            prefix: 0, //852
          },
          "address": {
            "city": "",
            "countryCode": "",
            "line1": "",
            "line2": "",
            "postalCode": "",
            "state": ""
          }
        }
      },
      token: '',
      errors: {},
      errorStr: '',
      message: {},
      edit: undefined,
      addressLoaded: false
    }
    this.unlisten = null;
    this.getAddress = this.getAddress.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderPhone = this.renderPhone.bind(this);
    this.gotoEdit = this.gotoEdit.bind(this);
    this.getMessage = this.getMessage.bind(this);
  }
  /*async componentDidUpdate(prevProps,prevState){
    const token = await tokenHeader();
    console.log(prevState.userAddress, this.state.userAddress);
    if(prevState.userAddress!== this.state.userAddress){
      console.log('did update');
      //this.getAddress(token);
    }
  }*/
  async componentDidMount() {
    this._isMounted = true;
    let paths = this.props.location.pathname.split('/');
    if (paths.indexOf("edit") > -1){
      if(this._isMounted){
        this.setState({edit: true });
      }
    }else{
      if(this._isMounted)
        this.setState({edit: false });
    }
      
    //this.getAddress(token);
    await this.getAddress();
    this.unlisten = this.props.history.listen((location, action) => {
      //console.log("on route change");
      paths = location.pathname.split('/');
      if (paths.indexOf("edit") > -1){
        if(this._isMounted){
          this.setState({edit: true });
        }
      }else{
        if(this._isMounted)
          this.setState({edit: false });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
    if(this.unlisten)
      this.unlisten();
  }
  async getAddress() {
    try {
      let res = await apiRequest.address.list();
      if (res.status === 200) {
        if (!isEmpty(res.data.result)) {
          res.data.result['shipToBillingAddress'] = false;
          if(this._isMounted)
            this.setState({ userAddress: res.data.result, addressLoaded: true });
        }
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  async getMessage(message) {
    if (message === "addressesUpdated") {
      if(this._isMounted)
        this.setState({ message: { addressesUpdated: true } });
    }
  }

  gotoEdit(action) {
    let _this = this;
    if (action === "edit") {
      this.props.history.push({
        pathname: "/manage/addresses/edit",
        search: window.location.search
      });
      if(this._isMounted)
        this.setState({ edit: true });
    } else {
      this.props.history.goBack();
      /* this.props.history.replace({
        pathname: '/manage/addresses',
        search: window.location.search
      }); */
      setTimeout(() => _this.getAddress(), 1000);
      if(this._isMounted)
        this.setState({ edit: false });
    }
  }

  handleClose() {
    this.setState({ message: {} })
  }

  renderPhone(countryCode, phone) {
    return (
      <GatewayContext.Consumer>
        {context =>
          context.countries.map((item, key) =>
            (item.code === countryCode) && <Fragment key={key}>+({item.callPrefix}) {phone}</Fragment>
          )
        }
      </GatewayContext.Consumer>
    )
  }

  render() {
    const { t, classes } = this.props;
    const { message, edit, addressLoaded } = this.state;
    const { phone, billingAddress, shippingAddress } = this.state.userAddress;
    //console.log(this.state.userAddress);
    // page data
    const headerData = {
      header: t("address:billing_and_shipping"),
      caption: t("address:billing_and_shipping_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const featureButtonData = [
      <IconLabelButton onClick={() => { this.gotoEdit('edit') }} startIcon={<EditIcon />}>{t("address:edit_billing_and_shipping")}</IconLabelButton>
    ]

    const billingAddressData = {
      header: t("address:billing_address"),
      data: billingAddress && [
        {
          title: t("address:address"),
          value: <Fragment>{billingAddress.line1}{billingAddress.line2 && <Fragment><br />{billingAddress.line2}</Fragment>}</Fragment>
        },
        {
          title: t("address:city"),
          value: billingAddress.city
        },
        {
          title: t("address:state"),
          value: billingAddress.state
        },
        {
          title: t("address:postal_code"),
          value: billingAddress.postalCode
        },
        {
          title: t("address:region"),
          value: t("regions:" + billingAddress.countryCode)
        },
        {
          title: t("address:phone"),
          value: '+' + phone.prefix + ' ' + phone.phoneNumber
        }
      ]
    }

    const shippingAddressData = {
      header: t("address:shipping_address"),
      data: shippingAddress && [
        {
          title: t("address:name"),
          value: shippingAddress.name
        },
        {
          title: t("address:address"),
          value: <Fragment>{shippingAddress.address.line1}{shippingAddress.address.line2 && <Fragment><br />{shippingAddress.address.line2}</Fragment>}</Fragment>
        },
        {
          title: t("address:city"),
          value: shippingAddress.address.city
        },
        {
          title: t("address:state"),
          value: shippingAddress.address.state
        },
        {
          title: t("address:postal_code"),
          value: shippingAddress.address.postalCode
        },
        {
          title: t("address:region"),
          value: t("regions:" + shippingAddress.address.countryCode)
        },
        {
          title: t("address:phone"),
          value: '+' + shippingAddress.phone.prefix + ' ' + shippingAddress.phone.phoneNumber
        }
      ]
    }
    //console.log(billingAddressData, shippingAddressData);

    if (addressLoaded) {
      if (edit) {
        return (
          <GatewayContext.Consumer>
            {context =>
              <Address fromDashboard={true} countries={context.countries} userAddress={this.state.userAddress} onCloseEdit={this.gotoEdit} />
            }
          </GatewayContext.Consumer>
        );
      } else {
        return (
          <Container maxWidth="sm" className={classes.root}>
            <RenderHeader {...headerData} />
            <RenderMessage data={messageData} />
            <RenderFeatureButton data={featureButtonData} />


            {billingAddress && shippingAddress ?
              <Fragment>
                <Box className={classes.sectionRoot}>
                  <RenderSectionTitle data={billingAddressData.header} />
                  <RenderDisplaySection data={billingAddressData.data} />
                </Box>

                <Box className={classes.sectionRoot}>
                  <RenderSectionTitle data={shippingAddressData.header} />
                  <RenderDisplaySection data={shippingAddressData.data} />
                </Box>
              </Fragment>
              : <Box className={classes.sectionRoot}>
                <Box textAlign="center">{t("address:no_address")}</Box>
              </Box>
            }
          </Container>
        )
      }
    } else {
      return (
        <Backdrop />
      )
    }

  }
}


export default withTranslation(["common", "errors", "address", "regions"])(withStyles(useStyles)(Addresses));
