import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isEmpty, emailCheck, findDuplicates } from "../../utils/Utils";
import { Paper, Container, Grid, Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, TablePagination } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from "@material-ui/core/styles";
import { NavigateBefore as NavigateBeforeIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { Backdrop, Typography, IconLabelButton, Button, IconButton, RenderHeader, RenderFooter, RenderFeatureButton, RenderDialog, TextField } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";
import { Licenses as useStyles } from "./Styles";
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

class Licenses extends Component {
  static displayName = Licenses.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.markers = [];
    this.myRef = React.createRef();
    this.state = {
      parentPath: "/manage/corporate/",
      errors: {},
      detail: {},
      stripeSubscriptionId: '',
      tenants: [],
      serachTenantResult:[],
      tenantsPaging: {},
      message: {},
      pageStatus: '',
      selected: [],
      addedItemsValue: '',
      emails: [],
      duplicates: [],
      errEmails: [],
      errorsData: {},
      page: 0,
      rowsPerPage: 50,
      fromPath: '',
      items: 1000,
      searchTimeoutId: -1,
      keywords: '',
    };
    this.getTenant = this.getTenant.bind(this);
    this.handleRouting = this.handleRouting.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCompleted = this.handleCompleted.bind(this);
    this.handleSelectTenant = this.handleSelectTenant.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.removeTanants = this.removeTanants.bind(this);
    this.addTanantsPreview = this.addTanantsPreview.bind(this);
    this.addTanants = this.addTanants.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.renderCaption = this.renderCaption.bind(this);
    this.showErrorTanants = this.showErrorTanants.bind(this);
    this.checkCurrPath = this.checkCurrPath.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.unlisten = null;
  }

  async componentDidMount() {
    this._isMounted = true;
    const { stripeSubscriptionId } = this.props.match.params;
    const path = this.props.location.pathname;
    if (stripeSubscriptionId) {
      this.setState({ stripeSubscriptionId: stripeSubscriptionId });
      await this.getSubscription(stripeSubscriptionId);
      await this.getTenant(stripeSubscriptionId);
      if (path.indexOf("add-tenants") > -1) {
        this.setState({ pageStatus: 'addTenant' });
      } else if (path.indexOf("delete-tenants") > -1) {
        this.setState({ pageStatus: 'deleteTenant' });
      } else
        this.setState({ pageStatus: '' });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      const path = location.pathname;
      //console.log(location.pathname, action)
      if (path.indexOf("add-tenants") > -1) {
        this.setState({ pageStatus: 'addTenant' });
      } else if (path.indexOf("delete-tenants") > -1) {
        this.setState({ pageStatus: 'deleteTenant' });
      } else
        this.setState({ pageStatus: '' });

    });
    this.setState({ fromPath: path });
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.unlisten)
      this.unlisten();
  }

  async getSubscription(stripeSubscriptionId) {
    try {
      const res = await apiRequest.subscription.detail(`${stripeSubscriptionId}?Includes=seat`);
      //const res = await axios.get('/api/v1/subscriptions/detail/' + stripeSubscriptionId, { headers: _token });
      //console.log(res);
      if (res.status === 200) {
        //console.log(res.data.result);
        this.setState({ detail: res.data.result })
      }
    } catch (err) {
      if (err.response.status === 404) {
        // this.setState({ message: { notFoundError: true } });
        //window.location.href = "/404";
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } });
      } else {
        this.setState({ message: { badRequestError: true } });
      }
    }
  }

  async getTenant(stripeSubscriptionId) {
    const { items } = this.state;
    try {
      let res = await apiRequest.tenants.list({ SubscriptionId: stripeSubscriptionId, limit: items });
      //console.log(res);
      if (res.status === 200) {
        this.setState({
          tenants: res.data.results,
          tenantsPaging: res.data.paging
        });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }
    }
  }

  async removeTanants() {
    const { stripeSubscriptionId, selected } = this.state;

    // const emails = selected.filter(o=>!emailCheck(o));
    // const duplicates = findDuplicates(selected);

    // console.log(emails, duplicates);
    const emails = selected.map(o => o.email);

    try {
      let res = await apiRequest.tenants.delete({ SubscriptionId: stripeSubscriptionId, emails: emails });
      //console.log('remove',res);
      if (res.status === 202) {
        await this.getTenant(stripeSubscriptionId);
        this.setState({
          message: { deleteCompleted: true }
        });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }

    }
  }
  showErrorTanants(emails, errEmails, duplicates) {

    const { editor } = this.myRef.current;
    let { addedItemsValue } = this.state;

    let errorsData = {
      exceeds: false,
      duplicates: [],
      invalids: [],
      counts: {
        invalids: 0,
        duplicates: 0
      }
    }
    //console.log(emails);
    this.markers.forEach(marker => marker.clear());

    //if (!isEmpty(errEmails) || !isEmpty(duplicates)) {

    //console.log(errEmails, duplicates, this.myRef.current, addedItemsValue);
    editor.setValue(addedItemsValue);
    let marker;
    if (duplicates.length > 0 || errEmails.length > 0) {
      emails.forEach((o, i) => {
        //console.log(o,i);
        if (errEmails.indexOf(o) > -1) {
          marker = editor.getDoc().markText({ line: i, ch: 0 }, { line: i, ch: o.length }, { css: "color : green" });
          errorsData.counts.invalids = errorsData.counts.invalids + 1;
          errorsData.invalids.push((i + 1));
          this.markers.push(marker);
        }

        if (duplicates.indexOf(o) > -1) {
          //doc.markText(from: {line, ch}, to: {line, ch}, ?options: object)
          marker = editor.getDoc().markText({ line: i, ch: 0 }, { line: i, ch: o.length }, { css: "color : red" });
          errorsData.duplicates.push((i + 1));
          errorsData.counts.duplicates = errorsData.counts.duplicates + 1;
          this.markers.push(marker);
        }

      })
    }
    this.setState({ emails: emails, errorsData: errorsData });

  }
  async addTanantsPreview() {
    let { addedItemsValue, detail } = this.state;
    let errorsData = {
      exceeds: false,
      duplicates: [],
      invalids: [],
      counts: {
        invalids: 0,
        duplicates: 0
      }
    }
    this.setState({ emails: [], errorsData: {} });
    //addedItemsValue = addedItemsValue.replace(/ +?/g, '');
    const emails = addedItemsValue.split('\n');
    try {

      const errEmails = emails.filter(o => !emailCheck(o));
      const duplicates = findDuplicates(emails);

      if (!isEmpty(errEmails) || !isEmpty(duplicates)) {
        this.showErrorTanants(emails, errEmails, duplicates);
        return;
      } else if (detail.subscription.quantity < emails.length) {
        errorsData.exceeds = true;
        this.setState({ emails: emails, errorsData: errorsData });
        return;
      } else {
        this.setState({ emails: emails });
        this.handleRouting("confirmAdd");
      }

    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }

    }
  }
  handleInputChange(ev) {
    clearTimeout(this.state.searchTimeoutId)
  
    this.setState({
      searchTimeoutId: setTimeout(() => {
        this.performSearch()
      }, 600),
      keywords: ev.target.value
    });
  }

  performSearch() {
    const { keywords, searchStatus, serachTenantResult, search ,tenants} = this.state;
    if (keywords.length > 0) {
      const newResults = [];
      tenants.forEach(item => {
        const keywordsCheck = item.email.toLowerCase().includes(keywords.toLowerCase());
        keywordsCheck && newResults.push(item);
      })
      // console.log(newResults);
      this.setState({
        serachTenantResult: newResults
      })
    }
  }


  async addTanants() {
    let { stripeSubscriptionId, emails } = this.state;
    //const { editor } = this.myRef.current;
    let errEmails = [];
    //addedItemsValue = addedItemsValue.replace(/ +?/g, '');

    try {//['exx@gmail.com','abc','exx@gmail.com','test@gmail.com','2@g']
      let res = await apiRequest.tenants.preview({ emails: emails, remarks: '' });
      //let res = await apiRequest.tenants.add({ SubscriptionId: stripeSubscriptionId, emails: emails, remarks: "" });
      if (res.status === 202) {
        res = await apiRequest.tenants.add({ SubscriptionId: stripeSubscriptionId, emails: emails, remarks: "" });
        //console.log('added',res);
        await this.getTenant(stripeSubscriptionId);
        this.setState({
          errEmails: [],
          message: { addCompleted: true }
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        console.log(err.response.data);
        const { error, errors } = err.response.data;
        // let marker;
        // this.markers.forEach(marker => marker.clear());
        if (!isEmpty(error)) {
          // console.log(error);
          if (error.message === "Invalid ModelState." && error.params.emails[0] === "You cannot add tenants more then the allowed quantity") {
            this.setState({ message: { overQuantity: true } })
          } else {
            this.setState({ message: { badRequestError: true } })
          }
          /*
            code: "bad_request"
            message: "Invalid ModelState."
            params:
            emails:
              0: "You cannot add tenants more then the allowed quantity"
            type: "validation_error"
          */

          this.handleRouting('showErrors');
        } else if (!isEmpty(errors)) {
          for (const [i] of Object.entries(errors)) {
            errEmails.push(emails[i]);
            //marker = editor.getDoc().markText({ line: i, ch: 0 }, { line: i, ch: emails[i].length }, { css: "color : green" });
          }
          this.handleRouting('showErrors');
          this.showErrorTanants(emails, errEmails, []);
          //this.markers.push(marker);
        }
      } else if (err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }
    }
  }
  checkCurrPath(check) {
    const { location } = this.props;
    const { fromPath } = this.state;
    //console.log((fromPath===location.pathname),fromPath,location.pathname);
    if (!check) {
      if (fromPath !== location.pathname) {
        this.setState({ fromPath: location.pathname });
        return true;
      } else {
        this.setState({ fromPath: location.pathname });
        return false;
      }
    } else {
      //console.log('update',location.pathname);
      this.setState({ fromPath: location.pathname });
    }

  }
  handleRouting(flag) {
    let data = {};
    /*
    // license -> add-tenants -> add-tenants (confirm)
    // license -> delete-tenants (confirm)
    */
    const { stripeSubscriptionId } = this.state;
    if (flag === "addTenant") {
      this.checkCurrPath(true);
      this.props.history.push({ pathname: `/manage/add-tenants/${stripeSubscriptionId}` });
      data = { addedItemsValue: '', pageStatus: "addTenant" };
    } else if (flag === "showErrors" || flag === "backToAddTenant") {
      data = { pageStatus: "addTenant" };
    } else if (flag === "confirmAdd") {
      data = { pageStatus: "confirmAdd" };
    } else if (flag === "deleteTenant") {
      if (this.state.selected.length > 0) {
        data = { pageStatus: "deleteTenant" };
        this.checkCurrPath(true);
        this.props.history.push({ pathname: `/manage/delete-tenants/${stripeSubscriptionId}` });

      }
      else data = { message: { noSelectItem: true } };
    } else if (flag === "backWithSelect") {
      //console.log('back 1');
      if (this.checkCurrPath())
        this.props.history.goBack();
      else
        this.props.history.replace({ pathname: `/manage/licenses/${stripeSubscriptionId}` });
      data = { pageStatus: "" };
    } else {
      //console.log('back 2');
      if (this.checkCurrPath())
        this.props.history.goBack();
      else
        this.props.history.replace({ pathname: `/manage/licenses/${stripeSubscriptionId}` });
      data = {
        pageStatus: "",
        selected: [],
        addedItemsValue: '',
        emails: [],
        errorsData: {}
      };
    }
    this.setState(data);
  }

  handleSelectTenant(email) {
    let selected = [...this.state.selected];
    let index = selected.indexOf(email);
    //console.log(email, index);
    if (index > -1) {
      selected.splice(index, 1);
    } else
      selected.push(email);
    //console.log(selected);
    this.setState({ selected: selected });

  }

  handleSelectAll() {
    const { selected, tenants } = this.state
    if (selected.length === tenants.length) {
      this.setState({ selected: [] })
    } else {
      this.setState({ selected: this.state.tenants.map(item => item) })
    }
  }

  handleClose() {
    this.setState({ message: {} });
  }

  handleCompleted() {
    this.setState({ message: {}, page: 0 })
    this.handleRouting();
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  renderCaption(caption) {
    return caption && <Typography variant="body1">{caption}</Typography>
  }

  render() {
    const { t, classes } = this.props;
    const { detail, tenants, pageStatus,
      stripeSubscriptionId, parentPath, selected, emails, errorsData, message, page, rowsPerPage,keywords ,serachTenantResult} = this.state;
    let headerData, footerData, caption, featureButtonData, messageData;


    if (pageStatus === 'addTenant') {
      headerData = {
        headerBefore: <IconButton edge="start" onClick={() => this.handleRouting()} className={classes.navigateBeforeRoot}><NavigateBeforeIcon className={classes.navigateBeforeIcon} /></IconButton>,
        header: t("subscription:add_tenant"),
      }

      caption = <span dangerouslySetInnerHTML={{ __html: t("subscription:add_tenants_caption") }} />;

      footerData = [
        <Button color="secondary" onClick={() => this.handleRouting()} fullWidth>{t("common:cancel")}</Button>,
        <Button onClick={this.addTanantsPreview} fullWidth>{t("common:next")}</Button>
      ]

    } else if (pageStatus === 'confirmAdd') {
      headerData = {
        headerBefore: <IconButton edge="start" onClick={() => this.handleRouting("backToAddTenant")} className={classes.navigateBeforeRoot}><NavigateBeforeIcon className={classes.navigateBeforeIcon} /></IconButton>,
        header: t("subscription:add_tenants_confirmation")
      }

      caption = <Fragment><strong>{emails.length}</strong>{t("subscription:confirm_add_tenants_caption")}</Fragment>;

      footerData = [
        <Button color="secondary" onClick={() => this.handleRouting("backToAddTenant")} fullWidth>{t("common:back")}</Button>,
        <Button color="secondary" onClick={() => this.handleRouting()} fullWidth>{t("common:cancel")}</Button>,
        <Button color="third" onClick={this.addTanants} fullWidth>{t("common:confirm")}</Button>
      ]

    } else if (pageStatus === 'deleteTenant') {
      headerData = {
        headerBefore: <IconButton edge="start" onClick={() => this.handleRouting("backWithSelect")} className={classes.navigateBeforeRoot}><NavigateBeforeIcon className={classes.navigateBeforeIcon} /></IconButton>,
        header: t("subscription:delete_tenants_confirmation")
      }

      caption = <Fragment><strong>{selected.length}</strong>{t("subscription:confirm_delete_tenants_caption")}</Fragment>;

      footerData = [
        <Button color="secondary" onClick={() => this.handleRouting("backWithSelect")} fullWidth>{t("common:back")}</Button>,
        <Button color="secondary" onClick={() => this.handleRouting()} fullWidth>{t("common:cancel")}</Button>,
        <Button color="third" onClick={this.removeTanants} fullWidth>{t("common:confirm")}</Button>
      ]

    } else {
      headerData = {
        headerBefore: <IconButton edge="start" component={Link} to={parentPath + stripeSubscriptionId} className={classes.navigateBeforeRoot}><NavigateBeforeIcon className={classes.navigateBeforeIcon} /></IconButton>,
        header: t("subscription:manage_licenses")
      }

      featureButtonData = [
        <IconLabelButton startIcon={<AddIcon />} onClick={() => this.handleRouting("addTenant")}>{t("subscription:add_tenant")}</IconLabelButton>,
        tenants.length < 1 ? <></> : selected.length === tenants.length ? <IconLabelButton color="warning" onClick={this.handleSelectAll}>{t("subscription:clear_select")}</IconLabelButton> : <IconLabelButton color="third" onClick={this.handleSelectAll}>{t("subscription:select_all")}</IconLabelButton>,
        <TextField value={keywords}  onChange={this.handleInputChange}  size="small" id="keywords" name="keywords" label={t("subscription:tenant_search_box")} />
      
      ]
    }

      headerData.caption = detail.subscription && <Fragment><strong>{detail.subscription.productName} {(detail.subscription.planName !== undefined && detail.subscription.planName !== null && detail.subscription.planName !== "") ? ` - ${detail.subscription.planName}` : ""}</strong> | {t("subscription:no_of_licenses")}: {detail.seat.total} | {t("subscription:use_of_licenses")}: {detail.seat.occupied}</Fragment>

    messageData = [
      {
        open: message.addCompleted,
        onClose: this.handleCompleted,
        severity: "success",
        title: t("common:completed"),
        children: emails.length + t("subscription:complete_add_tenants"),
      },
      {
        open: message.deleteCompleted,
        onClose: this.handleCompleted,
        severity: "success",
        title: t("common:completed"),
        children: selected.length + t("subscription:complete_delete_tenants"),
      },
      {
        open: message.overQuantity,
        onClose: this.handleClose,
        severity: "error",
        title: t("common:error"),
        children: t("subscription:add_tenants_error_over_quantity"),
      },
      {
        open: message.noSelectItem,
        onClose: this.handleClose,
        children: t("subscription:no_select")
      },
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const EnhancedTableToolbar = (props) => {
      const { numSelected } = props;
      return (
        <Toolbar>
          {numSelected > 0 ?
            <Fragment>
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">{numSelected}{t("subscription:selected")}</Typography>
              <IconButton onClick={() => this.handleRouting("deleteTenant")}><DeleteIcon /></IconButton>
            </Fragment>
            : <Typography className={classes.title} variant="h6" component="div"><strong>{t("subscription:list_of_tenants")}</strong></Typography>
          }
        </Toolbar>
      )
    }
    //console.log(pageStatus);
    if (pageStatus === 'addTenant') {
      return (
        <Container maxWidth="md" className={classes.root}>
          <RenderHeader {...headerData} />
          <RenderDialog data={messageData} />

          <Box component="section" className={classes.sectionRoot}>
            {this.renderCaption(caption)}
            <Grid container spacing={2} className={classes.addGridContainer}>
              <Grid item xs={12} md={6}>
                <CodeMirror
                  ref={this.myRef}
                  value={this.state.addedItemsValue}
                  options={{
                    mode: 'xml',
                    theme: 'material',
                    lineNumbers: true
                  }}
                  onBeforeChange={(editor, data, value) => {
                    this.setState({ addedItemsValue: value });
                  }}
                />
              </Grid>
              {!isEmpty(errorsData) &&
                <Grid item xs={12} md={6}>
                  <Alert severity="error">
                    <AlertTitle>{t("common:error")}</AlertTitle>
                    {errorsData.duplicates && errorsData.duplicates.length > 0 &&
                      <div>{t("subscription:line_numbers")} {errorsData.duplicates.join(',')} {t("subscription:duplicated_emails")}</div>
                    }
                    {errorsData.invalids && errorsData.invalids.length > 0 &&
                      <div>{t("subscription:line_numbers")} {errorsData.invalids.join(',')} {t("subscription:invalid_emails")}</div>
                    }
                    {errorsData.exceeds &&
                      <div>{t("subscription:email_exceeds")}</div>
                    }
                  </Alert>
                </Grid>
              }
              
            </Grid>
          </Box>

          <RenderFooter data={footerData} />
        </Container>
      )
    } else if (pageStatus === 'confirmAdd') {
      return (
        <Container maxWidth="md" className={classes.root}>
          <RenderHeader {...headerData} />
          <RenderDialog data={messageData} />

          <Box component="section" className={classes.sectionRoot}>
            {this.renderCaption(caption)}
            <Grid container spacing={2} className={classes.confirmGridContainer}>
              {emails.length > 0 && emails.map((item, i) =>
                <Grid item xs={12} md={6} lg={4} key={item + i}>{item}</Grid>
              )}
            </Grid>
          </Box>

          <RenderFooter data={footerData} />
        </Container>
      )
    } else if (pageStatus === 'deleteTenant') {
      return (
        <Container maxWidth="md" className={classes.root}>
          <RenderHeader {...headerData} />
          <RenderDialog data={messageData} />

          <Box component="section" className={classes.sectionRoot}>
            {this.renderCaption(caption)}
            <Grid container spacing={2} className={classes.confirmGridContainer}>
              {selected.length > 0 && selected.map(item =>
                <Grid item xs={12} md={6} lg={4} key={item.email}>{item.email}</Grid>
              )}
            </Grid>
          </Box>

          <RenderFooter data={footerData} />
        </Container>
      )
    } else if (!isEmpty(detail)) {
      return (
        <Container maxWidth="md" className={classes.root}>
          <RenderHeader {...headerData} />
          <RenderDialog data={messageData} />
          <RenderFeatureButton data={featureButtonData} />

          <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />

            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("subscription:select")}</TableCell>
                    <TableCell>{t("common:email")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keywords ? serachTenantResult.length > 0 && (rowsPerPage > 0
                    ? serachTenantResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : serachTenantResult).map(item => (
                      <TableRow key={item.email}>
                        <TableCell>
                          <Checkbox color="primary" checked={(selected.indexOf(item) > -1)} onChange={() => this.handleSelectTenant(item)} />
                        </TableCell>
                        <TableCell>{item.email}</TableCell>
                      </TableRow>
                    )):tenants.length > 0 && (rowsPerPage > 0
                    ? tenants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : tenants).map(item => (
                      <TableRow key={item.email}>
                        <TableCell>
                          <Checkbox color="primary" checked={(selected.indexOf(item) > -1)} onChange={() => this.handleSelectTenant(item)} />
                        </TableCell>
                        <TableCell>{item.email}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, { label: 'All', value: tenants.length }]}
              component="div"
              count={tenants.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Container>
      )
    } else {
      return (
        <Backdrop />
      )
    }
  }
}

export default withTranslation(["common", "errors", "subscription"])(withStyles(useStyles)(Licenses));