import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingLeft: 14,
    paddingRight: 14,
  }
}));

export default function (props) {
  const classes = useStyles(props);
  return (
    <Typography
      variant='body1'
      color="error"
      classes={{
        root: classes.root
      }}
      {...props}
    >{props.children}</Typography>
  )
}
