import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { isEmpty, tokenHeader} from "../../utils/Utils";
import { StripeProvider, Elements } from "react-stripe-elements";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add as AddIcon } from "@material-ui/icons";
import { Backdrop, RenderHeader, RenderMessage, RenderSection, RenderFooter, Typography, Select, Button } from "../UI/Core";
import { ChangePayment as useStyles, StripeElementsOptions } from "./Styles";
import InjectedAddNewPaymentForm from "../Payment/AddPaymentForm";
import apiRequest from "../../utils/apiRequest";


class ChangePayment extends Component {
  static displayName = ChangePayment.name;

  constructor(props) {
    super(props);
    this.state = {
      parentPath: (window.location.href.indexOf("bulk-subscriptions")>-1)?"/manage/bulk-subscriptions":"/manage/subscriptions",
      errors: {},
      token: {},
      stripeSubscriptionId: props.subscription.id,
      selectedPaymentId: props.subscription.paymentMethodId || '',
      message: {},
      disableSubmit: false,
      items: 50,
      addNewPayment: this.props.addNewPayment||false,
      type:'',
      invoice:{},
      plans:[],
      plan:{},
      //setDefaultPayment: (props.payments.length === 0) ? true : false,
      setDefaultPayment: process.env.REACT_APP_SET_DEFAULT_PAYMENT||true,
      stripeApiKey: process.env.REACT_APP_STRIPE_PUBLISHABLEKEY
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getPlans = this.getPlans.bind(this);
    this.handleCancelAddNewPayment = this.handleCancelAddNewPayment.bind(this);
    this.handleAddNewPayment = this.handleAddNewPayment.bind(this);
  }

  async componentDidMount() {
    const token = await tokenHeader();
    const {invoices, latestPayment, subscription} = this.props;
    let { plan } = this.props;
    let invoice, plans;
    if(latestPayment)
      invoice = invoices.find(o => o.stripeInvoiceId === latestPayment.stripeInvoiceId);
    else if(invoices.length>0)
      invoice = invoices[0];
    else if(isEmpty(plan)){
      plans = this.getPlans(true);
      const { stripePlanId } = subscription;
      plan = plans.find((o) => o.stripePlanId === stripePlanId);
      if(!isEmpty(plan))
        this.setState({plan:plan})
    }

    this.setState({invoice:invoice, token:token});

    //console.log(this.props);
  }

   async getPlans(flag) {
    try {
      const res = await apiRequest.plan.list({ type: ["Private", "Public"], status:['Active','Inactive']});
      if (res.status === 200) {
        this.setState({ plans: res.data.results, planPaging: res.data.paging });
        if(flag)
          return res.data.results;
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        this.setState({ message: { badRequestError: true } });
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } });
      }
    }
  }
  async changePaymentMethod(_token) {
    const { selectedPaymentId, stripeSubscriptionId, token } = this.state;
    let res;
    _token = (_token && _token.Authorization) ? _token : token;

    //console.log('selectedPaymentId',selectedPaymentId); 
    try {
      this.setState({ disableSubmit: true });
      if (stripeSubscriptionId === '') { // set default payment
        /*res = await axios.patch("/api/v1/account/payment-methods", {
          defaultPaymentMethodId: selectedPaymentId, headers: _token
        });*/
        res = await apiRequest.paymentMethod.setDefault({defaultPaymentMethodId: selectedPaymentId});
      } else { // change subscription payment
        //return;
        res = await apiRequest.paymentMethod.changePayment({
          paymentMethodId: selectedPaymentId,
          subscriptionId: stripeSubscriptionId
        });
      /*  res = await axios.patch("/api/v1/subscriptions/payment-methods", {
          paymentMethodId: selectedPaymentId,
          subscriptionId: stripeSubscriptionId, headers: _token
        });*/
      }
      let { status } = res;
      if (status === 200) {
        //window.location.reload();
        this.setState({
          message: { updateSuccessfully: true },
          disableSubmit: false
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { serverApiError: true }, disableSubmit: false
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }, disableSubmit: false
        });
      }
    }
  }
  static getDerivedStateFromProps(props, state) {
    if(props.addNewPayment!==state.addNewPayment)
      return {addNewPayment:props.addNewPayment};
    return null;
  }

  handleCancelAddNewPayment(e) {
    //e.preventDefault();
    this.setState({ addNewPayment: false});
    this.props.history.goBack();
  }
  handleAddNewPayment(){
    const {stripeSubscriptionId, parentPath} = this.state;
    this.setState({ addNewPayment: true });
    //console.log(`${parentPath}/paydue/add-payment/${stripeSubscriptionId}`);
    this.props.history.push({ pathname: `${parentPath}/paydue/add-payment/${stripeSubscriptionId}` });
  }

  handleClose() {
    this.setState({ message: {} })
  }
  handleCancel() {
    this.props.onCancel()
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }
  render() {
    const { t, i18n, classes } = this.props;
    const { message, selectedPaymentId, disableSubmit, invoice, addNewPayment, setDefaultPayment, stripeApiKey } = this.state;
    const { subscription, paymentMethod, plan, payments} = this.props;
    const locale = (i18n.language === "zh-CN") ? "zh" : i18n.language;
    
    //console.log(addNewPayment);
    //console.log(selectedPaymentId, stripeSubscriptionId, paymentMethod.id);
    const headerData = {
      header: t("payment:change_payment_method"),
      caption: t("payment:change_payment_method_caption")
    }
    const messageData = [
      {
        open: message.updateSuccessfully,
        onClose: this.handleCancel,
        severity: "success",
        children: t("payment:payment_method_updated")
      },
      {
        open: message.serverApiError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ];
    const footerData = [
      <Button color="secondary" onClick={this.handleCancel} fullWidth >{t("common:back")}</Button>,
      <Button onClick={this.changePaymentMethod} fullWidth >{t("common:update")}</Button>
    ];

    if (!isEmpty(subscription) && (!isEmpty(plan) || !isEmpty(invoice))  && !isEmpty(payments)) {
      if (!addNewPayment) {
        return (
          <Container maxWidth="md" className={classes.root}>
            <Backdrop variant="processing" open={disableSubmit} />
            <RenderHeader {...headerData} />
            <RenderMessage data={messageData} />

            <Box component="section" className={classes.sectionRoot}>
              <Grid container className={classes.gridContainer}>
                {(payments) && !isEmpty(paymentMethod) &&
                  <Grid item xs={12} md={6}>
                    <Select value={(selectedPaymentId !== "") ? selectedPaymentId : paymentMethod.id} onChange={this.handleInputChange} name="selectedPaymentId" label={t("payment:payment_method")} >
                      {payments.map(payment => (
                        <option key={payment.paymentMethodId} value={payment.id}>{payment.brand} **** **** **** {payment.last4} {t(payment.isDefault ? "payment:default_payment" : '')}</option>
                      ))}
                    </Select>
                  </Grid>
                }

                <Grid item xs={12} md={6}>
                  <Button color="secondary" startIcon={<AddIcon />} onClick={this.handleAddNewPayment}>{t("payment:add_new_payment_method")}</Button>
                </Grid>
              </Grid>
            </Box>

            <RenderSection data={[
              <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("payment:subscription_terms", { link: "/terms", className: classes.link }) }} className={classes.remark} />
            ]} />
            <RenderFooter data={footerData} />
          </Container>
        )
      } else {
        return (
          <StripeProvider apiKey={stripeApiKey}>
            <Elements key={locale} fonts={StripeElementsOptions.fonts} locale={locale}>
              <InjectedAddNewPaymentForm setDefault={setDefaultPayment} handleCancel={this.handleCancelAddNewPayment} />
            </Elements>
          </StripeProvider>
        )
      }
    } else {
      return (
        <Backdrop />
      )
    }
  }
}

export default withTranslation(["common", "payment", 'errors'])(withStyles(useStyles)(ChangePayment));