import React from "react";
import { injectStripe, CardElement } from "react-stripe-elements";
import { withTranslation } from "react-i18next";
import { tokenHeader } from "../../utils/Utils";
import clsx from "clsx";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AddPaymentForm as useStyles, CardStyle } from "./Styles";
import { Backdrop, RenderHeader, RenderMessage, RenderFooter, TextField, ErrorMessage, Button } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";

class AddPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHolderName: "",
      message: {},
      disableSubmit: false,
      errors: {},
      token: '',
      setDefault: props.setDefault || false,
      //setDefault: false,
      payments: [],
      newPaymentId: '',
      items:100
    }
    this.submitNewPayment = this.submitNewPayment.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.stripPayment = this.stripPayment.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getPayment = this.getPayment.bind(this);
    this.handleCloseBack = this.handleCloseBack.bind(this);
  }
  async componentDidMount() {
    const token = await tokenHeader();
    this.setState({ token: token });
  }
  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }
  handleValidation() {
    const { cardHolderName, errors } = this.state;

    if (cardHolderName === "")
      this.setState({ errors: { ...errors, cardHolderName: 'errors:is_required' }})
  }
  async getPayment(paymentMethodId) {
    const { items } = this.state;
    try {
      let res = await apiRequest.paymentMethod.list({limit:items});
      if (res.status === 200) {
        const newPayment = res.data.results.find(payment => payment.paymentMethodId === paymentMethodId);
        //console.log(newPayment, this.state.setDefault);
        if (newPayment && newPayment.id) {
          if (this.state.setDefault)
            this.setDefaultPaymentMethod(newPayment.id, res.data.results);
          else {
            this.setState({
              payments: res.data.results,
              newPaymentId: newPayment.id,
              disableSubmit: false,
              message: { cardIsAdded: true, title: 'payment:new_credit_card', message: 'payment:a_new_credit_card_is_added' }
            });
          }
        }
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ disableSubmit: false, message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ disableSubmit: false, message: { internalServerError: true } })
      }

    }
  }

  

  async submitNewPayment() {
    //e.preventDefault();
    const { stripe } = this.props;
    // if (!this.handleValidation()) {
    this.setState({
      disableSubmit: true,
      errors: {},
      message: {}
    });
    if (stripe) {
      try {
        //const { data } = await axios.post("/api/v1/account/payment-methods", { headers: _token });
        const { data } = await apiRequest.paymentMethod.add();
        const setupIntentSecret = data.result;
        this.handleValidation();
        this.stripPayment(setupIntentSecret);
      } catch (err) {
        console.log(err, err.response);
        if (err.response.status === 400 || err.response.status === 403) {
          this.setState({
            disableSubmit: false,
            message: { badRequestError: true }
          });
        } else if (err.response.status === 500) {
          this.setState({
            disableSubmit: false,
            message: { internalServerError: true }
          });
        }
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
    // }
  }
  async stripPayment(setupIntentSecret) {
    try {
      const { cardHolderName, errors } = this.state;
      const { stripe } = this.props;

      const cardElement = this.props.elements.getElement("card");
      // From here we can call createPaymentMethod to create a PaymentMethod
      // See our createPaymentMethod documentation for more:
      // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method

      // Card Num Samples: https://stripe.com/docs/testing
      const { setupIntent, error } = await stripe.confirmCardSetup(setupIntentSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardHolderName,
          },
        }
      });
      let _this = this;
      if (error) {
        this.setState({ disableSubmit: false });
        console.log(error);
        if (error.type === "validation_error" || error.type === "card_error" || (error.type === "invalid_request_error" && error.code === "setup_intent_authentication_failure")) {
          this.setState({ errors: { ...errors, CardElement: error.message } })
        } else if (error.type === "invalid_request_error" && error.code === "parameter_invalid_empty" && error.param === "payment_method_data[billing_details][name]") {
          this.setState({ errors: { ...errors, cardHolderName: "errors:is_required" } })
        }
        // this.setState({
        //   disableSubmit: false,
        //   message:{
        //     cardError:true,
        //     title:'stripe_errors:credit_card_error',
        //     message: error.message,
        //     type: error.type,
        //     decline_code:
        //         (error.decline_code && error.decline_code!=='') ? 'stripe_errors:'+error.decline_code
        //       : (error.code && error.code!=='') ? 'stripe_errors:'+error.code
        //       : 'stripe_errors:unknown_error' }
        // });
      } else if (setupIntent && setupIntent.status === "succeeded") {
        //console.log(setDefault, setupIntent);
        setTimeout(() => _this.getPayment(setupIntent.payment_method), 2000);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400) {
        this.setState({
          disableSubmit: false,
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          disableSubmit: false,
          message: { internalServerError: true }
        });
      }
    }
  }

  async setDefaultPaymentMethod(paymentId, payments) {
    //console.log('setDefaultPaymentMethod',paymentId, payments);
    try {
      if(this.state.setDefault){
        let { status } = await apiRequest.paymentMethod.setDefault({ 'defaultPaymentMethodId': paymentId });
        if (status === 202) {
          this.setState({
            disableSubmit: false,
            payments: payments,
            newPaymentId: paymentId,
            message: { cardIsAdded: true, title: 'payment:new_credit_card', message: 'payment:a_new_credit_card_is_added' }
          });
        }
      }else{
        this.setState({
          disableSubmit: false,
          payments: payments,
          newPaymentId: paymentId,
          message: { cardIsAdded: true, title: 'payment:new_credit_card', message: 'payment:a_new_credit_card_is_added' }
        });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          disableSubmit: false,
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          disableSubmit: false,
          message: { internalServerError: true }
        });
      }
    }
  }

  handleClose() {
    this.setState({
      message: {}
    });
  }

  handleCloseBack() {
    //window.location.reload();
    const { payments, newPaymentId } = this.state;
    //console.log(payments, newPaymentId);
    if (payments.length === 1 && newPaymentId !== '')
      this.props.handleCancel({ payments: payments, paymentId: newPaymentId }); // return to select first payment
    else if (payments.length > 1)
      this.props.handleCancel({ payments: payments }); // 
    else
      this.props.handleCancel();
  }

  render() {
    const { t, classes, handleCancel } = this.props;
    const { cardHolderName, errors, message, disableSubmit } = this.state;
    // console.log(message);
    // page data
    const headerData = {
      header: t("payment:add_new_payment_method"),
      caption: t("payment:add_new_payment_method_caption")
    }
    //console.log(message);
    const messageData = [
      // {
      //   open: message.cardError,
      //   onClose: this.handleClose,
      //   severity: "error",
      //   title: t(message.title),
      //   children: t(message.decline_code)
      // },
      {
        open: message.cardIsAdded,
        onClose: this.handleCloseBack,
        severity: "success",
        title: t(message.title),
        children: t(message.message)
      },
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const footerData = [
      <Button color="secondary" onClick={handleCancel} fullWidth>{t("common:cancel")}</Button>,
      <Button onClick={this.submitNewPayment} fullWidth disabled={disableSubmit}>{t("common:add")}</Button>
    ]

    return (
      <Container maxWidth="md" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              <TextField value={cardHolderName} onChange={this.handleInputChange} name="cardHolderName" label={t("payment:cardholder_name") + " *"} error={Boolean(errors["cardHolderName"])} disabled={disableSubmit} />
              {(errors["cardHolderName"]) && <ErrorMessage>{t("payment:cardholder_name")}{t("errors:is_required")}</ErrorMessage>}
            </Grid>

            <Grid item xs={12} md={6}>
              <CardElement style={CardStyle} className={clsx(classes.cardElement, errors["CardElement"] && classes.cardElementError)} disabled={disableSubmit} />
              {errors["CardElement"] && <ErrorMessage>{errors["CardElement"]}</ErrorMessage>}
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} />
      </Container>
    )
  }
}

export default withTranslation(["common", "payment", "errors", "stripe_errors"])(injectStripe(withStyles(useStyles)(AddPaymentForm)));
