import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'
//import { getLanguageCode } from "../../utils/Utils";
import { withRouter } from "react-router-dom";
import { Select } from '../UI/Core';
import { Language as useStyles } from "./Styles";
// import moment from 'moment/min/moment-with-locales';
import moment from "moment";
import "moment/min/locales";

function Language(props) {
  const { i18n } = useTranslation();
  const classes = useStyles(props);
  //const [curr_lang, setCurr_lang] = useState(getLanguageCode(queryString.parse(window.location.search).lang) || getLanguageCode(localStorage.getItem("lang")) || getLanguageCode((navigator.language || navigator.userLanguage).toLowerCase()));
  const [curr_lang, setCurrLang] = useState(i18n.language);
  const momentVars = process.env.REACT_APP_MOMENT_LANGUAGES.split(",");
  const langVars = process.env.REACT_APP_LANGUAGES.split(",");
  const langNameVars = process.env.REACT_APP_NAME_LANGUAGES.split(",");

  /*   if (i18n.language !== curr_lang) {
      curr_lang === "en" ? moment.locale("en-gb")
      : curr_lang === "zh-CN" ? moment.locale("zh-cn")
        : moment.locale("ja");
      i18n.changeLanguage(curr_lang, err => err && console.log('something went wrong loading', err));
    } */
  useEffect(() => {
    let lang = queryString.parse(window.location.search).lang || localStorage.getItem("lang") || navigator.language || navigator.userLanguage || 'en';
    if (i18n.language !== lang) {
      setCurrLang(lang);
    }
  }, [i18n])

  useEffect(() => {
    if (localStorage.getItem("lang") && localStorage.getItem("lang") !== curr_lang) {
      localStorage.setItem("lang", curr_lang);
      moment.locale(momentVars[langVars.indexOf(curr_lang)]);
      i18n.changeLanguage(curr_lang, err => err && console.log('something went wrong loading', err));
    }
  }, [curr_lang, i18n, momentVars, langVars]);


  const changeLanguage = ev => {
    const lng = ev.target.value;
    //console.log(curr_lang, lng);
    if (curr_lang !== lng) {
      setCurrLang(lng);
    }
  }

  return (
    <Select value={curr_lang} onChange={changeLanguage} classes={{ select: classes.select }} margin='none' fullWidth={false}>
      {langNameVars.map((name, i) => (
        <option value={langVars[i]} key={name}>{name}</option>
      ))}
    </Select>
  );
}

export default withRouter(Language);
